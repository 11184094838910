<template>
  <div>
    <messageBox ref="msgBox" />

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2"
          >mdi-microphone-settings</v-icon
        >
        <v-toolbar-title class="white--text">{{
          $t("speakers-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <div style="margin:15px">
        <v-row justify="center">
          <v-col class="text-center">
            <v-text-field
              :disabled="fetchingData || loading"
              :value="filter"
              @change="(v) => (filter = v)"
              append-icon="mdi-magnify"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col v-if="!$store.state.user.locked_type" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.types"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_types"
              :label="$t('guests-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.scheduler"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_schedulers"
              :label="$t('guests-filter-scheduler')"
              hide-details
            />
          </v-col>

          <v-col v-if="!$store.state.user.locked_locale" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.locale"
              outlined
              item-text="name"
              item-value="code"
              :items="flt_locales"
              :label="$t('guests-filter-locale')"
              hide-details
            />
          </v-col>

          <v-col v-if="!$store.state.user.locked_radio" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.radio"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_radios"
              :label="$t('guests-filter-radio')"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData || loading"
              clearable
              dense
              v-model="speaker"
              :items="speakers"
              :loading="isLoadingSpeaker"
              :search-input.sync="searchSpeakerInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-speaker')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingData"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :disabled="fetchingData || filters.filterByPeriod != 9"
                  :label="$t('filters-startdate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :disabled="fetchingData || filters.filterByPeriod != 9"
                  :label="$t('filters-enddate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="primary"
              icon="mdi-video-account"
              :title="$t('speakers-works-total-interviews')"
              :value="statistics.interviews"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <base-material-stats-card
              color="purple"
              icon="mdi-video-account"
              :title="$t('speakers-works-total-outcomes')"
              :value="statistics.outcomes"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="orange"
              icon="mdi-speaker"
              :title="$t('speakers-works-processing-audio')"
              :value="statistics.audio_processing"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="orange"
              icon="mdi-video-account"
              :title="$t('speakers-works-processing-video')"
              :value="statistics.video_processing"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="green"
              icon="mdi-speaker"
              :title="$t('speakers-works-total-audio')"
              :value="statistics.audios"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="green"
              icon="mdi-video-account"
              :title="$t('speakers-works-total-video')"
              :value="statistics.videos"
            />
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="guests"
        :item-class="row_classes"
        :options.sync="options"
        :server-items-length="totalGuests"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.warnings="{ item }">
          <div style="width:120px">
            <span
              class="ma-1"
              v-if="item.esito == 1 && item.days_from_interview > 1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-no-outcome") }}</span>
              </v-tooltip>
            </span>

            <span
              class="ma-1"
              v-if="
                !item.audio_duration &&
                  item.audio_queue_duration &&
                  (item.esito == 2 || item.esito == 8)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" dark v-bind="attrs" v-on="on">
                    mdi-refresh
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-audio-processing") }}</span>
              </v-tooltip>
            </span>
            <span
              class="ma-1"
              v-if="
                !item.video_duration &&
                  item.video_queue_duration &&
                  (item.esito == 2 || item.esito == 8)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" dark v-bind="attrs" v-on="on">
                    mdi-refresh
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-video-processing") }}</span>
              </v-tooltip>
            </span>

            <span
              class="ma-1"
              v-if="
                item.audio_duration &&
                  (item.esito == 2 || item.esito == 8) &&
                  getValidAudioDuration(item)
              "
            >
              <v-icon color="green">
                mdi-check
              </v-icon>
            </span>
            <span
              class="ma-1"
              v-if="
                item.video_duration &&
                  (item.esito == 2 || item.esito == 8) &&
                  getValidAudioDuration(item)
              "
            >
              <v-icon color="green">
                mdi-check
              </v-icon>
            </span>

            <span
              class="ma-1"
              v-if="
                !item.audio_duration &&
                  !item.audio_queue_duration &&
                  !item.audio_id &&
                  (item.esito == 2 || item.esito == 8)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-no-audio") }}</span>
              </v-tooltip>
            </span>
            <span
              class="ma-1"
              v-if="
                !item.video_duration &&
                  !item.video_queue_duration &&
                  !item.video_id &&
                  (item.esito == 2 || item.esito == 8)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-no-video") }}</span>
              </v-tooltip>
            </span>
            <span
              class="ma-1"
              v-if="!getValidAudioDuration(item) && (item.esito == 2 || item.esito == 8)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-audio-wrong-length") }}</span>
              </v-tooltip>
            </span>
            <span
              class="ma-1"
              v-if="!getValidVideoDuration(item) && (item.esito == 2 || item.esito == 8)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-video-wrong-length") }}</span>
              </v-tooltip>
            </span>

            <span
              class="ma-1"
              v-if="getInvalidVideoDiff(item) && (item.esito == 2 || item.esito == 8)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" dark v-bind="attrs" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ $t("speakers-warning-mismatch") }}</span>
              </v-tooltip>
            </span>
          </div>
        </template>

        <template v-slot:item.palinsesto_origine="{ item }">
          {{ item.nome_palinsesto }}
        </template>

        <template v-slot:item.id_radio="{ item }">
          {{ item.nome_radio }}
        </template>

        <template v-slot:item.ID="{ item }">
          <span v-html="getItemID(item)" />
        </template>

        <template v-slot:item.speaker="{ item }">
          <v-tooltip bottom
            ><template v-slot:activator="{ on }"
              ><v-icon v-if="item.nome_speaker" color="cyan" v-on="on"
                >mdi-information-outline</v-icon
              > </template
            ><span>{{ item.nome_speaker }}</span></v-tooltip
          >
          {{ item.codice_speaker }}
        </template>

        <template v-slot:item.nome_intervistato_1="{ item }">
          {{
            item.nome_intervistato_1 +
              (item.nome_intervistato_2 ? ", " + item.nome_intervistato_2 : "")
          }}
        </template>

        <template v-slot:item.data_intervista="{ item }">
          {{ item.data_intervista | toLocaleDate }}
        </template>

        <template v-slot:item.audio_duration="{ item }">
          {{
            item.audio_duration
              ? item.audio_duration
              : item.audio_queue_duration
              ? item.audio_queue_duration
              : ""
          }}
        </template>

        <template v-slot:item.video_duration="{ item }">
          {{
            item.video_duration
              ? item.video_duration
              : item.video_queue_duration
              ? item.video_queue_duration
              : ""
          }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import speakersManager from "../apis/speakers";
import guestsManager from "../apis/guests";
import staticData from "../apis/static";
import config from "../config";
import { DateTime as LuxonDateTime } from "luxon";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      searchSpeakerInput: null,

      speaker: null,

      guests: [],
      speakers: [],
      statistics: {
        interviews: "...",
        outcomes: "...",
        videos: "...",
        audios: "...",
        audio_processing: "...",
        video_processing: "...",
      },

      flt_locales: [],
      flt_radios: [],
      flt_schedulers: [],
      flt_types: [],

      isLoadingSpeaker: false,

      loading: false,

      totalGuests: 0,
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["data_intervista"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],
      filter: null,
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        outcome: null,
        coordinator: null,
        speaker: null,
        operator: null,
        scheduler: null,
        locale: "IT",
        radio: null,
        types: 2,
        filterByPeriod: 11,
      },

      previousView: 0,
      fetchingData: false,
    };
  },

  components: {
    messageBox,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  mounted() {
    this.computeHeaders();

    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];

    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.flt_types = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.types,
    ];

    this.speakers = [];
  },

  watch: {
    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then((res) => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    speaker: {
      handler() {
        this.filters.speaker = this.speaker ? this.speaker.id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadGuests(true);
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.guests = [];
        this.totalGuests = 0;
        this.computeHeaders();
        if (this.previousView != this.filters.view) {
          this.previousView = this.filters.view;
          this.options = {
            ...this.options,
            page: 1,
            sortBy: ["ID"],
            sortDesc: [true],
          };
        } else {
          clearTimeout(this._debounceTimer);
          this.options = {
            ...this.options,
            page: 1,
            sortBy: ["ID"],
            sortDesc: [true],
          };
        }
      },

      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },
    },
  },

  methods: {
    getPeriodFilters() {
      return staticData.filtersPeriods();
    },

    getInvalidVideoDiff(item) {
      let audio_duration = item.audio_duration;
      if (!audio_duration) audio_duration = item.audio_queue_duration;

      let video_duration = item.video_duration;
      if (!video_duration) video_duration = item.video_queue_duration;

      if (
        audio_duration != null &&
        video_duration != null &&
        Math.abs(audio_duration - video_duration) > config.allowedAudioVideoDiff
      )
        return true;
      return false;
    },

    getValidAudioDuration(item) {
      if (
        item.audio_duration != null &&
        item.audio_duration < config.audioMinimumDuration
      )
        return false;
      if (
        item.audio_queue_duration != null &&
        item.audio_queue_duration < config.audioMinimumDuration
      )
        return false;

      return true;
    },

    getValidVideoDuration(item) {
      if (
        item.video_duration != null &&
        item.video_duration < config.videoMinimumDuration
      )
        return false;

      if (
        item.video_queue_duration != null &&
        item.video_durvideo_queue_durationation < config.videoMinimumDuration
      )
        return false;

      return true;
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    getItemName(item) {
      let name = "";
      if (item.nome && item.nome.length > 0) name = item.nome;
      if (item.cognome && item.cognome.length > 0) name += " " + item.cognome;
      if (name.length < 1) name = item.nome_intervistato_1;
      if (name.length < 1) name = item.nome_intervistato_2;

      return name;
    },

    computeHeaders() {
      // Guests modo
      this.headers = [
        {
          text: this.$t("guests-tbl-code"),
          align: "left",
          sortable: true,
          value: "ID",
        },
        {
          text: this.$t("guests-tbl-name"),
          align: "left",
          sortable: true,
          value: "nome_intervistato_1",
        },
        {
          text: this.$t("guests-tbl-type"),
          align: "left",
          sortable: true,
          value: "nome_tipologia",
        },
        {
          text: this.$t("guests-tbl-radio"),
          align: "center",
          sortable: true,
          value: "id_radio",
        },

        {
          text: this.$t("guests-tbl-speaker"),
          align: "left",
          sortable: true,
          value: "speaker",
        },
        {
          text: this.$t("guests-tbl-interview-date"),
          align: "center",
          sortable: true,
          value: "data_intervista",
        },
        {
          text: this.$t("guests-tbl-source-scheduler"),
          align: "center",
          sortable: true,
          value: "palinsesto_origine",
        },

        {
          text: this.$t("guests-tbl-outcome"),
          align: "center",
          sortable: true,
          value: "esito_nome",
        },

        {
          text: this.$t("guests-tbl-audio-duration"),
          align: "center",
          sortable: true,
          value: "audio_duration",
        },

        {
          text: this.$t("guests-tbl-video-duration"),
          align: "center",
          sortable: true,
          value: "video_duration",
        },
        {
          text: this.$t("guests-tbl-days-from-interview"),
          align: "center",
          sortable: false,
          value: "days_from_interview",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "warnings",
        },

        { text: "", align: "center", sortable: false, value: "actions" },
      ];
    },

    row_classes(item) {
      if (item.esito == 1 && item.days_from_interview >= 1) {
        return "red--text text--darken-3";
      } else if (item.esito == 1 && item.days_from_interview == 0) {
        return "orange--text text--darken-3";
      } else if (
        (item.esito == 2 || item.esito == 8) &&
        item.days_from_interview > 0 &&
        ((!item.video_duration && !item.video_queue_duration) ||
          !item.audio_duration || !item.audio_queue_duration)
      ) {
        return "red--text text--darken-3";
      }
    },

    reloadGuests: function(showLoader) {
      this.guests = [];
      this.totalGuests = 0;
      this.statistics = {
        interviews: "...",
        outcomes: "...",
        videos: "...",
        audios: "...",
        audio_processing: "...",
        video_processing: "...",
      };

      this.fetchGuests(showLoader)
        .then((data) => {
          this.guests = data.items;
          this.statistics = data.statistics;
          let now = LuxonDateTime.utc();
          for (let n = 0; n < this.guests.length; n++) {
            let guest = this.guests[n];
            let time = LuxonDateTime.fromISO(guest.data_intervista).toUTC();
            let diff = now.diff(time, "days").toObject();
            let days = parseInt(Math.floor(diff.days));
            guest.days_from_interview = days;
            if (guest.days_from_interview < 0) guest.days_from_interview = 0;
          }
          this.totalGuests = data.totalCount;
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    },

    fetchUsers(filter) {
      if (this.$store.state.tr_locales) {
        this.flt_locales = [
          { code: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.tr_locales,
        ];
      }
      if (this.$store.state.radios) {
        this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.radios,
        ];
      }
      if (this.$store.state.schedulers) {
        this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.schedulers,
        ];
      }
      if (this.$store.state.types) {
        this.flt_types = [...this.$store.state.types];
      }

      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchGuests(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        speakersManager
          .fetchSpeakersGuests(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            console.log(err);
            reject();
          });
      });
    },
  },
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
