<template>
<v-dialog persistent overlay-opacity="0.6" max-width="500" :value="value" @input="closeDialog()"> <!-- @input is required to emit emit input if clicked outside unless we use persistent -->           
  <v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card class="pa-0" color="primary">
        <template v-slot:heading>
            <v-row class="pa-0">
              <v-col cols=1 class="pa-0"><v-icon large>mdi-home</v-icon></v-col>
              <v-col align="center" cols=10 class="pa-0 card-title-text" style="font-size: 25px">{{$t('fileupload-title')}}</v-col>
              <v-col cols=1 class="pa-0"><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col>
            </v-row>
          </template>
        <v-card-text>
          <v-file-input :disabled="uploadInProgress" v-model="file" accept="*" :label="$t('fileupload-select')"></v-file-input>
          <div style='height:10px'></div>
          <v-row v-if="uploadInProgress"><v-col cols=11><v-progress-linear striped style='height:24px' v-model="uploadProgressPerc"/></v-col>
          <v-col cols=1>
            <v-progress-circular v-if="s3SyncInProgress" :indeterminate="true" :rotate="0" :size="32" :width="4" color="light-blue"></v-progress-circular>
            </v-col></v-row>
          <div style='height:10px'></div>
          <div style='text-align:center'><v-btn v-if="file" :disabled="uploadInProgress" @click="submitFile" color="primary">{{$t('fileupload-upload')}}</v-btn></div>
        </v-card-text>
        </base-material-card>
       </v-flex>
    </v-layout>
  </v-container>
</v-dialog>
</template>

<script>

  import fileManager from '../apis/filemanager.js'

  export default {
    data() {
      return {
        file: null,
        fileSize: 0,
        fileSizeMax : 100000000,
        uploadProgressPerc : 0,
        path : '',
        item : null,
        uploadInProgress : false,
        s3SyncInProgress : false
      }
    },

    props : ['value'],

    methods: {
      fileUploadProgress(result) {
        this.fileSizeMax = result.total;
        this.fileSize = result.loaded;
        this.uploadProgressPerc = this.fileSize / this.fileSizeMax * 100.0;
        if (this.fileSize == this.fileSizeMax) this.s3SyncInProgress = true;
      },

      submitFile()
        {
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('path', this.path);
            this.uploadInProgress = true;
            fileManager.uploadFile(formData,this.fileUploadProgress).then(() => {
                this.uploadInProgress = false;
                this.s3SyncInProgress = false;
                this.$emit("uploadCompleted",{item : this.item, size: this.fileSizeMax, path : this.path + this.file.name, file: this.file.name});
                this.closeDialog();
            }).catch((err) => {
                this.uploadInProgress = false;
                this.s3SyncInProgress = false;
                console.log(err);
            })
      },

      closeDialog() {
        this.$emit('input');
      },

      resetModal() {
        this.file = null;
        this.fileSize = 0;
        this.fileSizeMax = 100000000;
        this.uploadProgressPerc = 0;
        this.uploadInProgress = false;
        this.s3SyncInProgress = false;
      },

      setPath(item,path) {
        this.path = path;
        this.item = item;
        this.resetModal();
      }
    }
  }
</script>