var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"1280","value":_vm.value}},[_c('SetString',{ref:"renamePermission"}),_c('MessageBox',{ref:"msgBox"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0 text-left",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-speaker")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("audioepisodes-add-episode-title")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-card-text',[_c('audioFilters',{attrs:{"filters":_vm.filters,"streamDates":false,"showDateType":false,"fetchingData":_vm.fetchingData,"streamStates":false,"waState":false,"mailState":false,"purchaseState":true},on:{"filtersReady":_vm.filtersReady}}),_c('v-card',{staticClass:"mt-2 elevation-2"},[_c('v-toolbar',{attrs:{"dense":"","color":"blue","dark":""}},[_c('v-col',{attrs:{"col":"1"}}),_c('v-col',[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-tbl-available-podcasts")))]),_c('v-col',{staticClass:"text-right",attrs:{"col":"1"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"height":"300","dense":"","must-sort":true,"headers":_vm.headers,"items":_vm.podcasts,"options":_vm.options,"server-items-length":_vm.totalPodcasts,"loading":_vm.fetchingData,"footer-props":_vm.dataTableFooterOptions,"show-select":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.guest_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" OSP-"+_vm._s(item.guest_id)+" ")]}},{key:"item.interview_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.interview_date))+" ")]}},{key:"item.select_state",fn:function(ref){
var item = ref.item;
return [(
                          !item.isSelectable &&
                            !_vm.episode.episode_podcasts.includes(item)
                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-check-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-tbl-already-included") + item.episode_name))])]):_vm._e(),(_vm.episode.episode_podcasts.includes(item))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true),model:{value:(_vm.selected_podcasts),callback:function ($$v) {_vm.selected_podcasts=$$v},expression:"selected_podcasts"}}),_c('v-btn',{attrs:{"disabled":_vm.selected_podcasts.length < 1,"color":"success"},on:{"click":function($event){return _vm.addSelected()}}},[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-add-selected")))])],1),_c('v-card',{staticClass:"mt-2 elevation-2"},[_c('v-toolbar',{attrs:{"dense":"","color":"blue","dark":""}},[_c('v-col',{attrs:{"col":"1"}}),_c('v-col',[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-tbl-episode-content")))]),_c('v-col',{attrs:{"col":"1"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mt-1 ml-1 mr-1",attrs:{"label":_vm.$t('audio-episodes-editor-hint-name'),"prepend-icon":"mdi-circle-edit-outline","error-messages":errors,"success":valid,"outlined":"","dense":"","required":""},model:{value:(_vm.episode.name),callback:function ($$v) {_vm.$set(_vm.episode, "name", $$v)},expression:"episode.name"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mt-1 ml-1 mr-1",attrs:{"label":_vm.$t('audio-episodes-editor-hint-description'),"prepend-icon":"mdi-circle-edit-outline","error-messages":errors,"success":valid,"outlined":"","dense":"","required":""},model:{value:(_vm.episode.description),callback:function ($$v) {_vm.$set(_vm.episode, "description", $$v)},expression:"episode.description"}})]}}],null,true)})],1)],1),_vm._l((_vm.episode.episode_podcasts),function(podcast){return _c('v-chip',{key:podcast.id,staticClass:"mr-1 mb-1",attrs:{"close":"","dark":"","close-icon":"mdi-delete","color":"purple","label":""},on:{"click:close":function($event){return _vm.removePodcast(podcast)}}},[_vm._v("OSP-"+_vm._s(podcast.guest_id))])}),_c('v-row',[_c('v-col',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-length"))+" : "+_vm._s(_vm.formatLength(_vm.episode.episode_length)))])],1),_c('v-row',[_c('v-col',[(_vm.buildingEpisode)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}}):_vm._e(),(!_vm.buildingEpisode)?_c('v-btn',{attrs:{"color":"success","disabled":invalid ||
                              !validated ||
                              _vm.episode.episode_length == 0},on:{"click":function($event){return _vm.buildEpisode()}}},[_vm._v(_vm._s(_vm.$t("audio-episodes-editor-build")))]):_vm._e()],1)],1)],2)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }