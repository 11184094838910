import axios from "axios";
import config from "../config";

let scheduler = {
  fetchModels: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/scheduler/fetchModels",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            models: resp.data.responseData.models,
            count: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteModel: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/scheduler/deleteModel",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateModel: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/scheduler/updateModel",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default scheduler;
