import axios from "axios";
import config from "../config";
const path = require("path");
import localStorageService from "../services/localStorageService";

let videoediting = {
  sendMail: (guest_id, mail_body,mail_html, mail_subject) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/sendMail",
        data: {
          guest_id: guest_id,
          mail_body: mail_body,
          mail_html : mail_html,
          mail_subject: mail_subject,
        },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  buidLink: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/buildLink",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  buildSourceLink: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/buildSourceLink",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadFile: (item) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/videoediting/download/" +
          item.ID +
          "?token=" +
          encodeURI(token)
      );
      link.setAttribute("target", "_blank");
      let filename = path.basename(item.path);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  uploadVideoEditorFile: (formData, progressCallback) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.apiEndPoint + "/videoediting/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: progressCallback.bind(this),
        })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeFile: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/removeFile",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  flagWorking: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/flagWorking",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeWorking: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/removeWorking",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  flagEditingCompleted: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/flagEditingCompleted",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeEditingCompleted: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/removeEditingCompleted",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeEditor: (guest_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/removeEditor",
        data: { guest_id: guest_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  assignEditor: (guest_id, editor_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/assignEditor",
        data: { guest_id: guest_id, editor_id: editor_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchEditors: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/videoediting/fetchEditors",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPendingGuestsCancelToken: axios.CancelToken.source(),

  fetchPendingGuests: (ctx) => {
    videoediting.fetchPendingGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/videoediting/pendingGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: videoediting.fetchPendingGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchAssignedGuestsCancelToken: axios.CancelToken.source(),

  fetchAssignedGuests: (ctx) => {
    videoediting.fetchAssignedGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/videoediting/assignedGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: videoediting.fetchAssignedGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchCompletedGuestsCancelToken: axios.CancelToken.source(),

  fetchCompletedGuests: (ctx) => {
    videoediting.fetchCompletedGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/videoediting/completedGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: videoediting.fetchCompletedGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchEditorAssignedGuestsCancelToken: axios.CancelToken.source(),

  fetchEditorAssignedGuests: (ctx) => {
    videoediting.fetchEditorAssignedGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/videoediting/editorAssignedGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: videoediting.fetchEditorAssignedGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchEditorCompletedGuestsCancelToken: axios.CancelToken.source(),

  fetchEditorCompletedGuests: (ctx) => {
    videoediting.fetchEditorCompletedGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/videoediting/editorCompletedGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: videoediting.fetchEditorCompletedGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },
};

export default videoediting;
