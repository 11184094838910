<template>
  <div>
    <messageBox ref="msgBox" />

    <audioEpisodeCreator
      @episodeConfigured="handleEpisode"
      ref="episodeEditor"
      v-model="episodeEditorDialog"
    ></audioEpisodeCreator>

    <episodeBuilderProgress ref="episodeBuilderProgress" />

    <v-card class="ma-1">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-cellphone-sound</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("audioepisodes-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("audioepisodes-episodes")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip v-if="$store.state.hasRight('150')" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="addEpisode()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("audioepisodes-add-episode") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :must-sort="true"
              :headers="headers"
              :items="episodes"
              :options.sync="options"
              :server-items-length="totalEpisodes"
              :loading="fetchingEpisodes"
              item-key="id"
            >
              <template v-slot:item.creation_date="{ item }">
                {{ item.creation_date | toLocaleDateTime }}
              </template>

              <template v-slot:item.episode_length="{ item }">
                {{ getFormattedEpisodeLength(item.episode_length) }}
              </template>

              <template v-slot:item.episode_state="{ item }">
                {{ getState(item.episode_state) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:80px">
                  <v-tooltip
                    v-if="
                      item.episode_state == 0 && $store.state.hasRight('151')
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="startItem(item)"
                        v-on="on"
                        ><v-icon>mdi-play</v-icon></v-btn
                      ></template
                    ><span>{{ $t("audio-episode-action-start") }}</span></v-tooltip
                  >
                  <v-tooltip
                    v-if="
                      item.episode_state == 2 && $store.state.hasRight('152')
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="downloadItem(item)"
                        v-on="on"
                        ><v-icon>mdi-arrow-down</v-icon></v-btn
                      ></template
                    ><span>{{ $t("audio-episode-action-download") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('153')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="deleteItem(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{ $t("audio-episode-action-delete") }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <div style="height:30px" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import messageBox from "../components/MessageBox";
import audioEpisodeCreator from "../components/audio/AudioEpisodeCreator";
import episodeBuilderProgress from "../components/audio/EpisodeBuilderProgress";
import audioManager from "../apis/audio";
import { Duration as LuxonDuration } from "luxon";
import localStorageService from '../services/localStorageService'
import config from '../config'

export default {
  data() {
    return {
      headers: [],
      episodes: [],
      totalEpisodes: 0,
      options: {},

      episodeEditorDialog: false,
      fetchingEpisodes: false,

      filter: null,
      filters: {
        region: null,
        category: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        dateTypes: 1,
        speakers: null,
        purchased: null,
        radio: null,
        locale: null,
      },
    };
  },

  components: {
    messageBox,
    audioEpisodeCreator,
    episodeBuilderProgress,
  },

  destroyed() {},

  mounted() {
    this.headers = [
      {
        text: this.$t("audio-episodes-tbl-name"),
        align: "left",
        sortable: true,
        value: "episode_name",
      },
      {
        text: this.$t("audio-episodes-tbl-description"),
        align: "left",
        sortable: true,
        value: "episode_description",
      },
      {
        text: this.$t("audio-episodes-tbl-date"),
        align: "center",
        sortable: true,
        value: "creation_date",
      },

      {
        text: this.$t("audio-episodes-tbl-num-items"),
        align: "left",
        sortable: true,
        value: "podcasts_count",
      },

      {
        text: this.$t("audio-episodes-tbl-length"),
        align: "center",
        sortable: false,
        value: "episode_length",
      },

      {
        text: this.$t("audio-episodes-tbl-state"),
        align: "center",
        sortable: false,
        value: "episode_state",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    options: {
      handler() {
        this.fetchEpisodes();
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    downloadItem: function(item) {
      const token = localStorageService.getAccessToken();
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/audio/episode/" +
          item.episode_id +
          "?token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      let filename = item.name + "_" + ".mp3";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    generateEpisodeProgressCallback(progress) {
      if (progress) {
        let lines = progress.split("\n");
        let line = "";
        while (lines.length > 0) {
          line = lines.pop();
          if (line.length > 0) break;
        }

        if (line.length > 0) {
          // Get status and progress
          let items = line.split(":");
          if (items.length >= 2) {
            let progress = items[0];
            let status = items[1];
            this.$refs.episodeBuilderProgress.setProgress(progress);
            this.$refs.episodeBuilderProgress.setStatus(status);
          }
        }
      }
    },

    startItem(item) {
      this.$refs.episodeBuilderProgress.show(
        this.$t("audio-episodes-start"),
        this.$t("audio-episodes-progress")
      );
      audioManager
        .generateEpisode(item.episode_id, this.generateEpisodeProgressCallback)
        .then(() => {
          this.$refs.episodeBuilderProgress.hide();
          this.fetchEpisodes();
        })
        .catch((err) => {
          this.$refs.episodeBuilderProgress.hide();
          console.log(err);
        });
    },

    getState(state) {
      if (state == 0) {
        return this.$t("gbl-on-hold");
      } else if (state == 1) {
        return this.$t("gbl-in-progress");
      } else if (state == 2) {
        return this.$t("gbl-completed");
      } else if (state == 3) {
        return this.$t("gbl-failed");
      }
    },

    getFormattedEpisodeLength(length) {
      let duration = LuxonDuration.fromMillis(length * 1000);
      duration = duration.shiftTo("hours", "minutes", "seconds").toObject();
      let formattedDuration = "{0} {1}, {2} {3}, {4} {5}".formatUnicorn(
        duration.hours.toFixed(0),
        this.$t("gbl-hours"),
        duration.minutes.toFixed(0),
        this.$t("gbl-minutes"),
        duration.seconds.toFixed(0),
        this.$t("gbl-seconds")
      );
      return formattedDuration;
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("audio-episodes-remove-text"),
          this.$t("audio-episodes-remove-title")
        )
        .then(() => {
          this.fetchingEpisodes = true;
          audioManager
            .deleteEpisode(item.episode_id)
            .then(() => {
              this.fetchEpisodes();
            })
            .catch((err) => {
              this.fetchingEpisodes = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addEpisode() {
      this.$refs.episodeEditor.setModalEpisode({}, false);
      this.episodeEditorDialog = true;
    },

    fetchEpisodes() {
      this.fetchingEpisodes = true;

      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      audioManager
        .fetchEpisodes(ctx)
        .then((result) => {
          this.episodes = result.items;
          this.totalEpisodes = result.totalCount;
          this.fetchingEpisodes = false;
        })
        .catch((err) => {
          this.fetchingEpisodes = false;
          console.log(err);
        });
    },

    handleEpisode(event) {
      if (!event) {
        this.episodeEditorDialog = false;
      } else if (event.editingEpisode) {
        //let episode = event.episode;
        /*applicationManager
          .updateApplication(app)
          .then(() => {
            this.reloadApplications(false);
          })
          .catch((err) => {
            console.log(err);
          });*/
      } else if (event.episode) {
        let episode = event.episode;
        audioManager
          .addEpisode(episode)
          .then(() => {
            this.episodeEditorDialog = false;
            this.fetchEpisodes();
          })
          .catch((err) => {
            this.episodeEditorDialog = false;
            console.log(err);
          });
      } else this.fetchEpisodes();
    },
  },
};
</script>
