<template>
  <v-card class="ma-4">
    <v-toolbar color="#0044a3" dark>
      <v-app-bar-nav-icon
        ><v-icon large>mdi-calendar</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{
        $t("scheduler-templates-title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="addModel()"><v-icon>mdi-plus</v-icon></v-btn>
    </v-toolbar>
    <div style="height:10px" />
    <v-card-text>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            
              <v-btn class="mr-1" icon small @click="prev">
                <v-icon  small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn class="ml-1"  icon small  @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title class="body-1" v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
       
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    focus: "",
    type: "month",

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
  
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
   
    
  },
};
</script>
