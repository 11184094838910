<template>
  <v-container justify-center id="login" tag="section">
    <ValidationObserver ref="pwdObs" v-slot="{ invalid, validated }">
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        :value="passwordDialog"
        @input="handlePasswordInput()"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="1" class="pa-2 ma-0"
                      ><v-icon large>mdi-lock</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="11"
                      class="pa-0 ma-0 card-title-text display-2"
                      >{{ $t("profile-change-pwd") }}</v-col
                    >
                  </v-row>
                </template>
                <div style="height:10px" />
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    color="secondary"
                    v-model="current_password"
                    :label="$t('profile-pwd-current-hint')"
                    prepend-icon="mdi-lock"
                    :append-icon="password_view_old ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="
                      () => (password_view_old = !password_view_old)
                    "
                    :type="password_view_old ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors, valid }"
                  vid="password_confirm"
                >
                  <v-text-field
                    color="secondary"
                    v-model="password"
                    :label="$t('profile-pwd-new-hint')"
                    prepend-icon="mdi-lock"
                    :append-icon="password_view_new ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="
                      () => (password_view_new = !password_view_new)
                    "
                    :type="password_view_new ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|confirmed:password_confirm"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    color="secondary"
                    v-model="repeat_password"
                    :label="$t('profile-pwd-repeat-hint')"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      password_view_confirm ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (password_view_confirm = !password_view_confirm)
                    "
                    :type="password_view_confirm ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>
                <div style="height:10px" />
                <v-btn
                  @click="handlePasswordChange()"
                  :disabled="invalid || !validated"
                  color="primary"
                  >{{ $t("profile-change-pwd-confirm") }}</v-btn
                >
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        persistent
        :value="cropperDialog"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="1" class="pa-2 ma-0"
                      ><v-icon large>mdi-account</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="11"
                      class="pa-0 ma-0 card-title-text display-2"
                      >{{ $t("profile-avatar-position") }}</v-col
                    >
                  </v-row>
                </template>
                <div style="height:20px" />
                <div v-show="croppieVisible">
                  <v-btn small text icon @click="rotate(-90)"
                    ><v-icon>mdi-rotate-right</v-icon></v-btn
                  >
                  <v-btn small text icon @click="rotate(90)"
                    ><v-icon>mdi-rotate-left</v-icon></v-btn
                  >
                  <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enable-resize="false"
                    :boundary="{ width: 128, height: 128 }"
                    :viewport="{ width: 128, height: 128, type: 'circle' }"
                  />
                </div>
                <div v-show="!croppieVisible">
                  <v-progress-circular
                    :enableExif="true"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                  <div style="height:20px" />
                </div>
                <!-- the result -->
                <img v-bind:src="croppedAvatar" />
                <v-btn
                  v-show="croppieVisible && !cropping"
                  @click="crop()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-btn
                  v-show="croppieVisible && !cropping"
                  @click="closeCrop()"
                  color="primary"
                  >{{ $t("gbl-cancel") }}</v-btn
                >
                <v-progress-circular
                  v-show="cropping"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="light-blue"
                ></v-progress-circular>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        :value="badPasswordDialog"
        @input="$emit('input')"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="red">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-2 ma-0"
                      ><v-icon large>mdi-lock</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="11"
                      class="pa-0 ma-0 card-title-text display-2"
                      >{{ $t("profile-change-pwd") }}</v-col
                    >
                  </v-row>
                </template>
                <div style="height:10px" />
                <v-card-text class="text-center">
                  {{ $t("profile-pwd-old-wrong") }}
                  <div style="height:10px" />
                  <v-btn @click="badPasswordDialog = false" color="primary">{{
                    $t("gbl-close")
                  }}</v-btn>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        persistent
        :value="dataChangedDialog"
        @input="dismissDataChangedDialog()"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-2 ma-0"
                      ><v-icon large>mdi-account</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="11"
                      class="pa-0 ma-0 card-title-text display-2"
                      >{{ $t("profile-change-data") }}</v-col
                    >
                  </v-row>
                </template>
                <div style="height:10px" />
                <v-card-text class="text-center">
                  {{ $t("profile-data-changed") }}
                  <div style="height:10px" />
                  <v-btn @click="dismissDataChanged()" color="primary">{{
                    $t("profile-logout")
                  }}</v-btn>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
    </ValidationObserver>
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card class="pa-0" color="primary">
          <template v-slot:heading>
            <v-row class="pa-0">
              <v-col cols="1" class="pa-0"
                ><v-icon large>mdi-account</v-icon></v-col
              >
              <v-col
                align="center"
                cols="10"
                class="pa-0 card-title-text"
                style="font-size: 25px"
                >{{ $t("profile-title") }}</v-col
              >
            </v-row>
          </template>
          <v-card-text class="text-center">
            <div class="subtitle-1">
              {{ $t("profile-hello", { name: getUsername }) }}
            </div>
            <v-row align="center">
              <v-col>
                <image-input v-model="avatarLoader">
                  <div slot="activator">
                    <v-btn text icon color="primary"
                      ><v-icon color="gray" size="32">mdi-camera</v-icon></v-btn
                    >
                    <p>{{ $t("profile-load-avatar") }}</p>
                  </div>
                </image-input>
              </v-col>
              <v-col>
                <v-avatar
                  size="128px"
                  v-ripple
                  v-if="croppedAvatar"
                  class="mb-3"
                >
                  <img :src="croppedAvatar" />
                </v-avatar>
                <v-avatar
                  v-if="!hasGravatar && !croppedAvatar"
                  color="indigo"
                  size="128px"
                  v-ripple
                  class="mb-3"
                >
                  <span class="profile-text">{{
                    getProfileLetters
                  }}</span>
                </v-avatar>
                <v-avatar
                  v-if="hasGravatar && !croppedAvatar"
                  size="128px"
                  v-ripple
                  class="mb-3"
                >
                  <img :src="getGravatar()" />
                </v-avatar>
              </v-col>
              <v-col>
                <v-btn
                  :disabled="gravatarMode == 1"
                  @click="useGravatar()"
                  text
                  icon
                  color="primary"
                  ><v-icon color="gray" size="32"
                    >mdi-account-circle</v-icon
                  ></v-btn
                >
                <p>{{ $t("profile-use-avatar") }}</p>
              </v-col>
            </v-row>

            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <ValidationProvider
                immediate
                rules="required"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  color="secondary"
                  v-model="first_name"
                  :label="$t('profile-hint-name')"
                  prepend-icon="mdi-account"
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </ValidationProvider>
              <ValidationProvider
                immediate
                rules="required"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  color="secondary"
                  v-model="last_name"
                  :label="$t('profile-hint-surname')"
                  prepend-icon="mdi-account"
                  :error-messages="errors"
                  :success="valid"
                  required
                />
              </ValidationProvider>
              <ValidationProvider
                immediate
                rules="required|email"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  color="secondary"
                  v-model="email"
                  :label="$t('profile-hint-email')"
                  prepend-icon="mdi-email"
                  :error-messages="errors"
                  :success="valid"
                  required
                  readonly
                />
              </ValidationProvider>
              <ValidationProvider
                immediate
                rules="required"
                v-slot="{ errors, valid }"
              >
                <v-select
                  color="secondary"
                  prepend-icon="mdi-message-text-outline"
                  hide-details
                  :label="$t('profile-hint-language')"
                  dense
                  v-model="locale"
                  item-text="text"
                  item-value="value"
                  :error-messages="errors"
                  :success="valid"
                  :items="locales"
                />
              </ValidationProvider>
              <span v-if="new_mail_error" style="color:red">{{
                $t("profile-mail-not-available")
              }}</span>
              <div style="height:10px" />
              <v-btn
                @click="updateProfile()"
                :disabled="invalid || !validated"
                color="primary"
                >{{ $t("profile-update") }}</v-btn
              >
              <div style="height:10px" />
              <v-btn @click="showPasswordModal()" color="primary">{{
                $t("profile-change-password")
              }}</v-btn>
            </ValidationObserver>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<style scoped>
.profile-circle {
  background: #eb6e39;
  color: #ffffff;
  vertical-align: middle;
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
  font-size: 30px;
  border-radius: 50%;
}
</style>

<script>
import userManager from "../apis/users";
import ImageUpload from "../components/ImageUpload.vue";
import avatars from "../services/avatars";
import localStorageService from "../services/localStorageService";
import i18n from "../plugins/i18n";
import config from "../config";
import { Settings } from 'luxon'
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      locales: [
        { value: "it", text: this.$t("profile-locales-it") },
        { value: "en", text: this.$t("profile-locales-en") },
        { value: "cz", text: this.$t("profile-locales-cz") },
        { value: "fr", text: this.$t("profile-locales-fr") },
      ],
      password_view_old: String,
      password_view_new: String,
      password_view_confirm: String,
      repeat_password: "",
      password: "",
      current_password: "",
      email: "",
      last_name: "",
      first_name: "",
      locale: this.$store.state.user.locale,
      new_mail_error: false,
      updatingProfile: false,
      passwordDialog: false,
      badPasswordDialog: false,
      dataChangedDialog: false,

      gravatar: null,
      avatarLoader: null,
      savingAvatar: false,
      savedAvatar: false,

      cropperDialog: false,
      cropperDialogURL: false,
      croppedAvatar: null,
      croppieVisible: false,
      cropping: false,

      avatarChanged: false,
      gravatarMode: null, // Letters
    };
  },

  mounted() {
    this.last_name = this.$store.state.user.last_name;
    this.first_name = this.$store.state.user.first_name;
    this.email = this.$store.state.user.email;
    this.gravatar = this.$store.state.user.avatar;
    this.gravatarMode = this.$store.state.user.gravatar;
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    ImageInput: ImageUpload,
  },

  watch: {
    avatarLoader: {
      handler: function() {
        if (this.avatarLoader) {
          this.saved = false;
          this.cropperDialog = true;
          this.croppieVisible = false;
          this.croppedAvatar = null;
          let that = this;
          setTimeout(function() {
            that.croppieVisible = true;
            that.$refs.croppieRef.bind({
              url: that.avatarLoader.imageURL,
            });
          }, 400);
        } else {
          this.croppieVisible = false;
        }
      },
      deep: true,
    },

    email: {
      handler: function() {
        if (!this.croppedAvatar && this.gravatarMode == true) {
          clearTimeout(this._debounceTimer);
          this._debounceTimer = setTimeout(() => {
            avatars
              .fetchGravatar(this.email)
              .then((result) => {
                this.gravatar = result;
              })
              .catch(() => {
                this.gravatar = null;
              });
          }, config.searchInputsTimeout);
        }
      },
    },
  },

  computed: {
    isLoggedIn: function() {
      return this.$store.state.user;
    },

    getUsername: function() {
      return this.$store.state.user.first_name;
    },

    getProfileLetters: function() {
      if (this.first_name && this.last_name) {
        if (this.first_name.length && this.last_name.length) {
          return (
            this.first_name.toUpperCase()[0] + this.last_name.toUpperCase()[0]
          );
        } else if (this.first_name && this.first_name.length >= 2) {
          return (
            this.first_name.toUpperCase()[0] + this.first_name.toUpperCase()[1]
          );
        } else if (this.first_name && this.first_name.length >= 1) {
          return (
            this.first_name.toUpperCase()[0] + this.first_name.toUpperCase()[0]
          );
        } else if (this.last_name && this.last_name.length >= 2) {
          return (
            this.last_name.toUpperCase()[0] + this.last_name.toUpperCase()[1]
          );
        } else if (this.last_name && this.last_name.length >= 1) {
          return (
            this.last_name.toUpperCase()[0] + this.last_name.toUpperCase()[0]
          );
        }
      }
      return "";
    },

    hasGravatar: function() {
      return this.gravatar ? true : false;
    },
  },
  methods: {
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    getGravatar: function() {
      return "data:image/jpeg;base64," + this.gravatar;
    },

    crop() {
      let options = {
        format: "jpeg",
        size: { width: 128, height: 128 },
      };
      this.cropping = true;

      this.$refs.croppieRef.result(options, (output) => {
        userManager
          .uploadAvatar(output.replace("data:image/jpeg;base64,", ""))
          .then(() => {
            this.croppedAvatar = output;
            this.cropperDialog = false;
            this.croppieVisible = true;
            this.avatarChanged = true;
            this.gravatar = null;
            this.gravatarMode = 0;
            this.cropping = false;
          })
          .catch((err) => {
            console.log(err);
            this.cropping = false;
          });
      });
    },

    closeCrop() {
      this.cropperDialog = false;
      this.croppieVisible = true;
      this.croppedAvatar = null;
    },

    useGravatar() {
      // Fetch a new gravatar using the email address
      avatars
        .fetchGravatar(this.email)
        .then((result) => {
          this.gravatar = result;
          this.croppedAvatar = null;
          this.avatarChanged = true;
          this.gravatarMode = 1;
        })
        .catch(() => {
          this.gravatar = null;
        });
    },

    handlePasswordInput: function() {
      this.$emit("input");
      this.passwordDialog = false;
      this.$refs.obs.validate(); // Revalidate the bottom form observer
      this.$refs.pwdObs.reset();
    },

    checkFormEmail: function() {
      return new Promise((resolve, reject) => {
        if (this.email == this.$store.state.user.email) {
          resolve();
        } else {
          this.new_mail_error = false;
          userManager
            .checkMail(this.email, this.$store.state.user.id)
            .then((result) => {
              if (result.data.responseData.count != 0) {
                if (result.data.responseData.count > 0) {
                  // Mail not valid, give a warning
                  this.new_mail_error = true;
                  reject();
                } else {
                  this.new_mail_error = true;
                  reject();
                }
              } else {
                resolve();
              }
            })
            .catch((err) => {
              this.new_mail_error = true;
              reject(err);
            });
        }
      });
    },

    dismissDataChanged: function() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },

    updateProfile: function() {
      this.updatingProfile = true;
      this.checkFormEmail()
        .then(() => {
          let gravatarMode = 0;
          if (!this.gravatar && !this.croppedAvatar) gravatarMode = null;
          else if (this.croppedAvatar) gravatarMode = 0;
          else gravatarMode = 1;
          this.gravatarMode = gravatarMode;
          this.$store.state.user.gravatar = gravatarMode;
          avatars.removeAvatarFromCache(this.$store.state.user.id);

          userManager
            .updateProfile({
              gravatar: this.gravatarMode,
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              id: this.$store.state.user.id,
              locale: this.locale,
            })
            .then(() => {
              this.updatingProfile = false;

              if (gravatarMode == false) {
                let filteredAvatar = this.croppedAvatar.replace(
                  "data:image/jpeg;base64,",
                  ""
                );
                this.$store.state.user.avatar = filteredAvatar;
                localStorageService.setAvatar(filteredAvatar);
              } else if (gravatarMode == true) {
                this.$store.state.user.avatar = this.gravatar;
                localStorageService.setAvatar(this.gravatar);
              }

              let localeChanged = false;
              if (this.$store.state.user.locale != this.locale) {
                this.$store.state.user.locale = this.locale;
                localStorageService.setLocale(this.locale);
                i18n.locale = this.locale;
                Settings.defaultLocale = i18n.locale;
                localeChanged = true;
              }

              if (this.email != this.$store.state.user.email) {
                this.$store.state.user.email = this.email;
                this.dataChangedDialog = true;
              }

              if (localeChanged) {
                this.$router.push("/").then(() => {
                  this.$router.go(0); // Force a reload of the APP
                });
              } else {
                this.$router.push("/");
              }
            })
            .catch((err) => {
              this.updatingProfile = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.updatingProfile = false;
        });
    },

    handlePasswordChange: function() {
      userManager
        .changePassword(this.current_password, this.password)
        .then((result) => {
          if (result.data.responseData.result) {
            this.$refs.pwdObs.reset();
            this.passwordDialog = false;
          } else {
            this.$refs.pwdObs.reset();
            this.passwordDialog = false;
            this.badPasswordDialog = true;
          }
        })
        .catch((err) => {
          this.$refs.pwdObs.reset();
          this.passwordDialog = false;
          this.badPasswordDialog = true;
          console.log(err);
        });
    },

    showPasswordModal: function() {
      this.current_password = "";
      this.password = "";
      this.repeat_password = "";
      this.passwordDialog = true;
    },
  },
};
</script>
