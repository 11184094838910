var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('EditorsFileUploader',{ref:"fileUploader",on:{"uploadCompleted":_vm.handleUploadCompleted},model:{value:(_vm.fileUploader),callback:function ($$v) {_vm.fileUploader=$$v},expression:"fileUploader"}}),_c('ChooseEditor',{ref:"chooseEditor",on:{"onOperatorChoosen":_vm.onOperatorChoosen}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"color":"#0044a3","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-video-account")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("videoediting-title")))]),_c('v-spacer')],1),(_vm.fetchingFilters)?_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}})],1)],1):_vm._e(),_c('div',{staticClass:"ml-1 mr-1 mt-2 mb-0"},[(!_vm.fetchingFilters)?_c('v-row',{staticClass:"ma-1",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-select',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"dense":"","outlined":"","item-text":"name","item-value":"id","items":_vm.flt_types,"label":_vm.$t('guests-filter-type'),"hide-details":""},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('v-col',{staticClass:"text-center"},[_c('v-select',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"dense":"","outlined":"","item-text":"name","item-value":"id","items":_vm.flt_schedulers,"label":_vm.$t('guests-filter-scheduler'),"hide-details":""},model:{value:(_vm.filters.scheduler),callback:function ($$v) {_vm.$set(_vm.filters, "scheduler", $$v)},expression:"filters.scheduler"}})],1),_c('v-col',{staticClass:"text-center"},[_c('v-select',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"dense":"","outlined":"","item-text":"name","item-value":"code","items":_vm.flt_locales,"label":_vm.$t('guests-filter-locale'),"hide-details":""},model:{value:(_vm.filters.locale),callback:function ($$v) {_vm.$set(_vm.filters, "locale", $$v)},expression:"filters.locale"}})],1),_c('v-col',{staticClass:"text-center"},[_c('v-select',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"dense":"","outlined":"","item-text":"name","item-value":"id","items":_vm.flt_radios,"label":_vm.$t('guests-filter-radio'),"hide-details":""},model:{value:(_vm.filters.radio),callback:function ($$v) {_vm.$set(_vm.filters, "radio", $$v)},expression:"filters.radio"}})],1)],1):_vm._e(),(!_vm.fetchingFilters)?_c('v-row',{staticClass:"ma-1",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-text-field',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"value":_vm.filter,"append-icon":"mdi-magnify","label":_vm.$t('gbl-search'),"hide-details":"","outlined":"","dense":"","clearable":""},on:{"change":function (v) { return (_vm.filter = v); }}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"clearable":"","dense":"","items":_vm.operators,"loading":_vm.isLoadingOperator,"search-input":_vm.searchOperatorInput,"cache-items":"","outlined":"","item-text":"name","item-value":"id","label":_vm.$t('guests-filter-operator'),"return-object":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchOperatorInput=$event},"update:search-input":function($event){_vm.searchOperatorInput=$event}},model:{value:(_vm.operator),callback:function ($$v) {_vm.operator=$$v},expression:"operator"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"clearable":"","dense":"","items":_vm.coordinators,"loading":_vm.isLoadingCoordinator,"search-input":_vm.searchCoordinatorInput,"cache-items":"","outlined":"","item-text":"name","item-value":"id","label":_vm.$t('guests-filter-coordinator'),"return-object":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchCoordinatorInput=$event},"update:search-input":function($event){_vm.searchCoordinatorInput=$event}},model:{value:(_vm.coordinator),callback:function ($$v) {_vm.coordinator=$$v},expression:"coordinator"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.fetchingFilters ||
                _vm.fetchingPendingGuests ||
                _vm.fetchingAssignedGuests ||
                _vm.fetchingCompletedGuests,"clearable":"","dense":"","items":_vm.speakers,"loading":_vm.isLoadingSpeaker,"search-input":_vm.searchSpeakerInput,"cache-items":"","outlined":"","item-text":"name","item-value":"id","label":_vm.$t('guests-filter-speaker'),"return-object":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchSpeakerInput=$event},"update:search-input":function($event){_vm.searchSpeakerInput=$event}},model:{value:(_vm.speaker),callback:function ($$v) {_vm.speaker=$$v},expression:"speaker"}})],1),_c('v-col',[_c('v-dialog',{ref:"dialog_start_date",attrs:{"return-value":_vm.filters.filterByCustomDateStart,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.fetchingFilters ||
                    _vm.fetchingPendingGuests ||
                    _vm.fetchingAssignedGuests ||
                    _vm.fetchingCompletedGuests,"dense":"","outlined":"","label":_vm.$t('guests-filter-datestart'),"readonly":"","clearable":""},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}],null,false,3082842491),model:{value:(_vm.date_start_modal),callback:function ($$v) {_vm.date_start_modal=$$v},expression:"date_start_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_start_date.save(_vm.filters.filterByCustomDateStart)}},model:{value:(_vm.filters.filterByCustomDateStart),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateStart", $$v)},expression:"filters.filterByCustomDateStart"}})],1)],1),_c('v-col',[_c('v-dialog',{ref:"dialog_end_date",attrs:{"return-value":_vm.filters.filterByCustomDateEnd,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.fetchingFilters ||
                    _vm.fetchingPendingGuests ||
                    _vm.fetchingAssignedGuests ||
                    _vm.fetchingCompletedGuests,"dense":"","outlined":"","label":_vm.$t('guests-filter-dateend'),"readonly":"","clearable":""},model:{value:(_vm.end_date_formatted),callback:function ($$v) {_vm.end_date_formatted=$$v},expression:"end_date_formatted"}},on))]}}],null,false,1029046907),model:{value:(_vm.date_end_modal),callback:function ($$v) {_vm.date_end_modal=$$v},expression:"date_end_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_end_date.save(_vm.filters.filterByCustomDateEnd)}},model:{value:(_vm.filters.filterByCustomDateEnd),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateEnd", $$v)},expression:"filters.filterByCustomDateEnd"}})],1)],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("videoediting-pending")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.pendingGuestsHeaders,"items":_vm.pendingGuests,"options":_vm.pendingGuestsOptions,"server-items-length":_vm.totalPendingGuests,"loading":_vm.fetchingPendingGuests,"item-key":"id"},on:{"update:options":function($event){_vm.pendingGuestsOptions=$event}},scopedSlots:_vm._u([{key:"item.data_intervista",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_intervista))+" ")]}},{key:"item.data_convalida",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_convalida))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_pagamento))+" ")]}},{key:"item.ID",fn:function(ref){
                    var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getItemID(item))}})]}},{key:"item.media_states",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"width":"48px"}},[(!item.audio_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-audio")))])])],1):_vm._e(),(!item.video_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-video")))])])],1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"width":"62px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.sendWA(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-wa")))])]),(_vm.$store.state.hasRight('139'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.assignEditor(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-assign-editor")))])]):_vm._e()],1)]}}])})],1)],1),_c('div',{staticStyle:{"height":"30px"}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("videoediting-assigned")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.assignedGuestsHeaders,"items":_vm.assignedGuests,"options":_vm.assignedGuestsOptions,"server-items-length":_vm.totalAssignedGuests,"loading":_vm.fetchingAssignedGuests,"item-key":"id"},on:{"update:options":function($event){_vm.assignedGuestsOptions=$event}},scopedSlots:_vm._u([{key:"item.path",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.path ? item.path.split("/").slice(-1)[0] : "")+" ")]}},{key:"item.data_intervista",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_intervista))+" ")]}},{key:"item.data_convalida",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_convalida))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_pagamento))+" ")]}},{key:"item.videoediting_working_datetime",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_working_datetime))+" ")]}},{key:"item.ID",fn:function(ref){
                    var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getItemID(item))}})]}},{key:"item.media_states",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"width":"64px"}},[(!item.audio_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-audio")))])])],1):_vm._e(),(!item.video_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-video")))])])],1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px"}},[(_vm.$store.state.hasRight('172'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.uploadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-upload")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('171'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-download")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('173'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-remove-file")))])]):_vm._e(),(
                    item.video_id &&
                      !item.path &&
                      _vm.$store.state.hasRight('174')
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"purple"},on:{"click":function($event){$event.stopPropagation();return _vm.buildSourceLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-source-link")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('174'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.buildLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-link")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.sendWA(item)}}},on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-wa")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.sendMail(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-email-send")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-mail")))])]),(_vm.$store.state.hasRight('170'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeEditor(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-remove")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-remove-editor")))])]):_vm._e(),(_vm.$store.state.hasRight('179'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(!item.videoediting_working_datetime)?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.flagWorking(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-force-set-working")))])]):_vm._e(),(_vm.$store.state.hasRight('179'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.videoediting_working_datetime)?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeWorking(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-force-unset-working")))])]):_vm._e(),(_vm.$store.state.hasRight('179'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.flagEditingCompleted(item)}}},on),[_c('v-icon',[_vm._v("mdi-flag")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-force-complete")))])]):_vm._e()],1)]}}])})],1)],1),_c('div',{staticStyle:{"height":"30px"}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("videoediting-completed")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.completedGuestsHeaders,"items":_vm.completedGuests,"options":_vm.completedGuestsOptions,"server-items-length":_vm.totalCompletedGuests,"loading":_vm.fetchingCompletedGuests,"item-key":"id"},on:{"update:options":function($event){_vm.completedGuestsOptions=$event}},scopedSlots:_vm._u([{key:"item.path",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.path ? item.path.split("/").slice(-1)[0] : "")+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px"}},[(_vm.$store.state.hasRight('172'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.uploadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-upload")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('171'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-download")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('173'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-remove-file")))])]):_vm._e(),(
                    item.video_id &&
                      !item.path &&
                      _vm.$store.state.hasRight('174')
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"purple"},on:{"click":function($event){$event.stopPropagation();return _vm.buildSourceLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-source-link")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('174'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.buildLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-link")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.sendCompletedWA(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-wa")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.sendMail(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-email-send")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-mail")))])]),(_vm.$store.state.hasRight('170'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeEditor(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-remove")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-remove-editor")))])]):_vm._e(),(_vm.$store.state.hasRight('179'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.removeEditingCompleted(item)}}},on),[_c('v-icon',[_vm._v("mdi-flag")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-remove-complete")))])]):_vm._e()],1)]}},{key:"item.data_intervista",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_intervista))+" ")]}},{key:"item.data_convalida",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_convalida))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_pagamento))+" ")]}},{key:"item.videoediting_working_datetime",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_working_datetime))+" ")]}},{key:"item.videoediting_completed_datetime",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_completed_datetime))+" ")]}},{key:"item.ID",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getItemID(item))}})]}},{key:"item.media_states",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"width":"48px"}},[(!item.audio_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-audio")))])])],1):_vm._e(),(!item.video_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-video")))])])],1):_vm._e()])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }