<template>
  <div>
    <messageBox ref="msgBox" />
    <EditorsFileUploader
      ref="fileUploader"
      @uploadCompleted="handleUploadCompleted"
      v-model="fileUploader"
    />
    <ChooseEditor @onOperatorChoosen="onOperatorChoosen" ref="chooseEditor" />
    <v-card class="ma-2">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-video-account</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("videoediting-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row dense v-if="fetchingFilters" class="text-center">
        <v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <div class="ml-1 mr-1 mt-2 mb-0">
        <v-row class="ma-1" v-if="!fetchingFilters" justify="center">
          <v-col class="text-center">
            <v-select
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              dense
              v-model="filters.type"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_types"
              :label="$t('guests-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              dense
              v-model="filters.scheduler"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_schedulers"
              :label="$t('guests-filter-scheduler')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              dense
              v-model="filters.locale"
              outlined
              item-text="name"
              item-value="code"
              :items="flt_locales"
              :label="$t('guests-filter-locale')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              dense
              v-model="filters.radio"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_radios"
              :label="$t('guests-filter-radio')"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row class="ma-1" v-if="!fetchingFilters" justify="center">
          <v-col class="text-center">
            <v-text-field
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              :value="filter"
              @change="(v) => (filter = v)"
              append-icon="mdi-magnify"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              clearable
              dense
              v-model="operator"
              :items="operators"
              :loading="isLoadingOperator"
              :search-input.sync="searchOperatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-operator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              clearable
              dense
              v-model="coordinator"
              :items="coordinators"
              :loading="isLoadingCoordinator"
              :search-input.sync="searchCoordinatorInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-coordinator')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :disabled="
                fetchingFilters ||
                  fetchingPendingGuests ||
                  fetchingAssignedGuests ||
                  fetchingCompletedGuests
              "
              clearable
              dense
              v-model="speaker"
              :items="speakers"
              :loading="isLoadingSpeaker"
              :search-input.sync="searchSpeakerInput"
              cache-items
              outlined
              item-text="name"
              item-value="id"
              :label="$t('guests-filter-speaker')"
              return-object
              hide-no-data
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingFilters ||
                      fetchingPendingGuests ||
                      fetchingAssignedGuests ||
                      fetchingCompletedGuests
                  "
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('guests-filter-datestart')"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="
                    fetchingFilters ||
                      fetchingPendingGuests ||
                      fetchingAssignedGuests ||
                      fetchingCompletedGuests
                  "
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('guests-filter-dateend')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-card-text>
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("videoediting-pending")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :headers="pendingGuestsHeaders"
              :items="pendingGuests"
              :options.sync="pendingGuestsOptions"
              :server-items-length="totalPendingGuests"
              :loading="fetchingPendingGuests"
              item-key="id"
            >
              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.data_convalida="{ item }">
                {{ item.data_convalida | toLocaleDate }}
              </template>

              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | toLocaleDate }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.media_states="{ item }">
                <div style="width:48px;">
                  <span v-if="!item.audio_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-audio") }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!item.video_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-video") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="width:62px; ">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="sendWA(item)"
                        v-on="on"
                        ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("videoediting-action-wa") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('139')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="assignEditor(item)"
                        v-on="on"
                        ><v-icon>mdi-account-plus</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-assign-editor")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <div style="height:30px" />

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("videoediting-assigned")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :headers="assignedGuestsHeaders"
              :items="assignedGuests"
              :options.sync="assignedGuestsOptions"
              :server-items-length="totalAssignedGuests"
              :loading="fetchingAssignedGuests"
              item-key="id"
            >
              <template v-slot:item.path="{ item }">
                {{ item.path ? item.path.split("/").slice(-1)[0] : "" }}
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.data_convalida="{ item }">
                {{ item.data_convalida | toLocaleDate }}
              </template>

              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | toLocaleDate }}
              </template>

              <template v-slot:item.videoediting_working_datetime="{ item }">
                {{ item.videoediting_working_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.media_states="{ item }">
                <div style="width:64px;">
                  <span v-if="!item.audio_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-audio") }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!item.video_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-video") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="width:200px; ">
                  <v-tooltip v-if="$store.state.hasRight('172')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="uploadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-upload</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-upload")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('171')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="downloadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-download</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('173')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeFile(item)"
                        v-on="on"
                        ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-remove-file")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      item.video_id &&
                        !item.path &&
                        $store.state.hasRight('174')
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="purple"
                        @click.stop="buildSourceLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-source-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('174')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="buildLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="sendWA(item)"
                        v-on="on"
                        ><v-icon>mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("videoediting-action-wa") }}</span></v-tooltip
                  >
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="sendMail(item)"
                        v-on="on"
                        ><v-icon color="primary">mdi-email-send</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-mail")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('170')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeEditor(item)"
                        v-on="on"
                        ><v-icon>mdi-account-remove</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-remove-editor")
                    }}</span></v-tooltip
                  >
                  <v-tooltip v-if="$store.state.hasRight('179')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        v-if="!item.videoediting_working_datetime"
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="flagWorking(item)"
                        v-on="on"
                        ><v-icon>mdi-check</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-force-set-working")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('179')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        v-if="item.videoediting_working_datetime"
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeWorking(item)"
                        v-on="on"
                        ><v-icon>mdi-check</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-force-unset-working")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('179')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="flagEditingCompleted(item)"
                        v-on="on"
                        ><v-icon>mdi-flag</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-force-complete")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <div style="height:30px" />

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("videoediting-completed")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :headers="completedGuestsHeaders"
              :items="completedGuests"
              :options.sync="completedGuestsOptions"
              :server-items-length="totalCompletedGuests"
              :loading="fetchingCompletedGuests"
              item-key="id"
            >
              <template v-slot:item.path="{ item }">
                {{ item.path ? item.path.split("/").slice(-1)[0] : "" }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:80px"></div>
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.data_convalida="{ item }">
                {{ item.data_convalida | toLocaleDate }}
              </template>

              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | toLocaleDate }}
              </template>

              <template v-slot:item.videoediting_working_datetime="{ item }">
                {{ item.videoediting_working_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.videoediting_completed_datetime="{ item }">
                {{ item.videoediting_completed_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.media_states="{ item }">
                <div style="width:48px;">
                  <span v-if="!item.audio_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-audio") }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!item.video_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-video") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="width:200px;">
                  <v-tooltip v-if="$store.state.hasRight('172')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="uploadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-upload</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-upload")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('171')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="downloadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-download</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('173')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeFile(item)"
                        v-on="on"
                        ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-remove-file")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="
                      item.video_id &&
                        !item.path &&
                        $store.state.hasRight('174')
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="purple"
                        @click.stop="buildSourceLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-source-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('174')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="buildLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="sendCompletedWA(item)"
                        v-on="on"
                        ><v-icon color="green">mdi-whatsapp</v-icon></v-btn
                      ></template
                    ><span>{{ $t("videoediting-action-wa") }}</span></v-tooltip
                  >

                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="sendMail(item)"
                        v-on="on"
                        ><v-icon color="primary">mdi-email-send</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-mail")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('170')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeEditor(item)"
                        v-on="on"
                        ><v-icon>mdi-account-remove</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-remove-editor")
                    }}</span></v-tooltip
                  >
                  <v-tooltip v-if="$store.state.hasRight('179')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="removeEditingCompleted(item)"
                        v-on="on"
                        ><v-icon>mdi-flag</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-remove-complete")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import videoEditing from "../apis/videoediting";
import guestsManager from "../apis/guests";
import config from "../config";

import messageBox from "../components/MessageBox";
import ChooseEditor from "../components/video/ChooseEditor";
import EditorsFileUploader from "../components/EditorsFileUploader";

export default {
  data() {
    return {
      pendingGuestsHeaders: [],
      assignedGuestsHeaders: [],
      completedGuestsHeaders: [],
      pendingGuests: [],
      assignedGuests: [],
      completedGuests: [],
      totalPendingGuests: 0,
      totalAssignedGuests: 0,
      totalCompletedGuests: 0,

      fetchingPendingGuests: false,
      fetchingAssignedGuests: false,
      fetchingCompletedGuests: false,
      fetchingFilters: false,

      pendingGuestsOptions: {
        sortBy: ["data_pagamento"],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },

      assignedGuestsOptions: {
        sortBy: ["data_pagamento"],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },

      completedGuestsOptions: {
        sortBy: ["data_pagamento"],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },

      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,
      searchCoordinatorInput: null,
      searchSpeakerInput: null,

      operator: null,
      speaker: null,
      coordinator: null,

      guests: [],
      operators: [],
      coordinators: [],
      speakers: [],
      users: [],

      flt_outcomes: [],
      flt_locales: [],
      flt_radios: [],
      flt_types: [],
      flt_schedulers: [],

      flt_users: {},

      isLoadingOperator: false,
      isLoadingCoordinator: false,
      isLoadingSpeaker: false,

      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        outcome: null,
        coordinator: null,
        speaker: null,
        operator: null,
        scheduler: null,
        type: null,
        locale: "IT",
        radio: null,
      },

      filter: null,
      fileUploader: false,
    };
  },

  name: "VideoEditing",

  components: {
    messageBox,
    ChooseEditor,
    EditorsFileUploader,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  destroyed() {},

  mounted() {
    this.pendingGuestsHeaders = [
      {
        text: this.$t("guests-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("guests-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "data_intervista",
      },

      {
        text: this.$t("guests-tbl-validation-date"),
        align: "center",
        sortable: true,
        value: "data_convalida",
      },

      {
        text: this.$t("guests-tbl-payment-date"),
        align: "center",
        sortable: true,
        value: "data_pagamento",
      },

      {
        text: this.$t("guests-tbl-name"),
        align: "left",
        sortable: false,
        value: "nome_intervistato_1",
      },

      {
        text: this.$t("guests-tbl-packet-version"),
        align: "left",
        sortable: false,
        value: "nome_pacchetto",
      },
      { text: "", align: "center", sortable: false, value: "media_states" },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];

    this.assignedGuestsHeaders = [
      {
        text: this.$t("guests-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("guests-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "data_intervista",
      },

      {
        text: this.$t("guests-tbl-validation-date"),
        align: "center",
        sortable: true,
        value: "data_convalida",
      },

      {
        text: this.$t("guests-tbl-payment-date"),
        align: "center",
        sortable: true,
        value: "data_pagamento",
      },

      {
        text: this.$t("guests-tbl-name"),
        align: "left",
        sortable: false,
        value: "nome_intervistato_1",
      },

      {
        text: this.$t("guests-tbl-packet-version"),
        align: "left",
        sortable: false,
        value: "nome_pacchetto",
      },

      {
        text: this.$t("guests-tbl-editor-name"),
        align: "left",
        sortable: false,
        value: "editor_name",
      },

      {
        text: this.$t("guests-tbl-date-started"),
        align: "center",
        sortable: false,
        value: "videoediting_working_datetime",
      },

      {
        text: this.$t("guests-tbl-path"),
        align: "left",
        sortable: false,
        value: "path",
      },

      { text: "", align: "center", sortable: false, value: "media_states" },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];

    this.completedGuestsHeaders = [
      {
        text: this.$t("guests-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("guests-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "data_intervista",
      },

      {
        text: this.$t("guests-tbl-validation-date"),
        align: "center",
        sortable: true,
        value: "data_convalida",
      },

      {
        text: this.$t("guests-tbl-payment-date"),
        align: "center",
        sortable: true,
        value: "data_pagamento",
      },

      {
        text: this.$t("guests-tbl-name"),
        align: "left",
        sortable: false,
        value: "nome_intervistato_1",
      },

      {
        text: this.$t("guests-tbl-packet-version"),
        align: "left",
        sortable: false,
        value: "nome_pacchetto",
      },

      {
        text: this.$t("guests-tbl-editor-name"),
        align: "center",
        sortable: false,
        value: "editor_name",
      },

      {
        text: this.$t("guests-tbl-date-started"),
        align: "center",
        sortable: false,
        value: "videoediting_working_datetime",
      },

      {
        text: this.$t("guests-tbl-date-completed"),
        align: "center",
        sortable: false,
        value: "videoediting_completed_datetime",
      },

      {
        text: this.$t("guests-tbl-path"),
        align: "left",
        sortable: false,
        value: "path",
      },

      { text: "", align: "center", sortable: false, value: "media_states" },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];

    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];

    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.flt_types = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.types,
    ];

    this.operators = [];
    this.speakers = [];
    this.coordinators = [];

    this.fetchUsers()
      .then((res) => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map((x) => {
          this.flt_users[x.id] = x;
        });
        this.fetchingFilters = false;
      })
      .catch((err) => {
        this.fetchingFilters = false;
        console.log(err);
      });
  },

  watch: {
    pendingGuestsOptions: {
      handler() {
        this.fetchPendingGuests();
      },
      deep: true,
    },

    assignedGuestsOptions: {
      handler() {
        this.fetchAssignedGuests();
      },
      deep: true,
    },

    completedGuestsOptions: {
      handler() {
        this.fetchCompletedGuests();
      },
      deep: true,
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchCoordinatorInput(val) {
      clearTimeout(this._searchCoordinatorInput);
      if (val) {
        this._searchCoordinatorInput = setTimeout(() => {
          this.isLoadingCoordinator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.coordinators = res.items;
              this.isLoadingCoordinator = false;
            })
            .catch(() => {
              this.isLoadingCoordinator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then((res) => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      },
    },

    speaker: {
      handler() {
        this.filters.speaker = this.speaker ? this.speaker.id : null;
      },
    },

    coordinator: {
      handler() {
        this.filters.coordinator = this.coordinator
          ? this.coordinator.id
          : null;
      },
    },

    filters: {
      handler() {
        this.guests = [];
        this.totalGuests = 0;
        this.pendingGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.assignedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.completedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };
      },

      deep: true,
    },

    filter: {
      handler() {
        this.pendingGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.assignedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.completedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };
      },
    },
  },

  methods: {
    onOperatorChoosen(payload) {
      videoEditing
        .assignEditor(payload.item, payload.selection)
        .then(() => {
          this.$refs.chooseEditor.closeDialog();
          this.fetchAssignedGuests();
          this.fetchPendingGuests();
        })
        .catch((err) => {
          this.$refs.chooseEditor.closeDialog();
          console.log(err);
        });
    },

    assignEditor(item) {
      this.$refs.chooseEditor.openModal(item.ID);
    },

    fetchUsers(filter) {
      if (this.$store.state.tr_locales) {
        this.flt_locales = [
          { code: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.tr_locales,
        ];
      }
      if (this.$store.state.radios) {
        this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.radios,
        ];
      }
      if (this.$store.state.schedulers) {
        this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.schedulers,
        ];
      }

      if (this.$store.state.types) {
        this.flt_types = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.types,
        ];
      }

      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    fetchPendingGuests() {
      this.fetchingPendingGuests = true;
      this.pendingGuests = [];
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.pendingGuestsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = this.pendingGuestsOptions.sortBy[0];
        ctx.sortDesc = this.pendingGuestsOptions.sortDesc[0];
        videoEditing
          .fetchPendingGuests(ctx)
          .then((result) => {
            this.pendingGuests = result.items;
            this.totalPendingGuests = result.totalCount;
            this.fetchingPendingGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingPendingGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    fetchAssignedGuests() {
      this.fetchingAssignedGuests = true;
      this.assignedGuests = [];
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.assignedGuestsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = this.assignedGuestsOptions.sortBy[0];
        ctx.sortDesc = this.assignedGuestsOptions.sortDesc[0];
        videoEditing
          .fetchAssignedGuests(ctx)
          .then((result) => {
            this.assignedGuests = result.items;
            this.totalAssignedGuests = result.totalCount;
            this.fetchingAssignedGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingAssignedGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    fetchCompletedGuests() {
      this.fetchingCompletedGuests = true;
      this.completedGuests = [];
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.completedGuestsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = this.completedGuestsOptions.sortBy[0];
        ctx.sortDesc = this.completedGuestsOptions.sortDesc[0];
        videoEditing
          .fetchCompletedGuests(ctx)
          .then((result) => {
            this.completedGuests = result.items;
            this.totalCompletedGuests = result.totalCount;
            this.fetchingCompletedGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingCompletedGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    removeEditor(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-editor-text"),
          this.$t("videoediting-remove-editor-title")
        )
        .then(() => {
          videoEditing.removeEditor(item.ID).then(() => {
            this.fetchCompletedGuests();
            this.fetchPendingGuests();
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    flagEditingCompleted(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-flag-completed-text"),
          this.$t("videoediting-flag-completed-title")
        )
        .then(() => {
          videoEditing.flagEditingCompleted(item.ID).then(() => {
            this.fetchCompletedGuests();
            this.fetchPendingGuests();
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    removeEditingCompleted(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-completed-text"),
          this.$t("videoediting-remove-completed-title")
        )
        .then(() => {
          videoEditing.removeEditingCompleted(item.ID).then(() => {
            this.fetchCompletedGuests();
            this.fetchPendingGuests();
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    flagWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-flag-working-text"),
          this.$t("videoediting-flag-working-title")
        )
        .then(() => {
          videoEditing.flagWorking(item.ID).then(() => {
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    removeWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-working-text"),
          this.$t("videoediting-remove-working-title")
        )
        .then(() => {
          videoEditing.removeWorking(item.ID).then(() => {
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    openUrl(url) {
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    async sendMail(item) {
      let text = null;
      let html = null;
      let subject = config.emails.rrEditingSubject
      if (item.path) {
        text = config.emails.rrEditing;
        html = config.emails.rrEditing;
        let final_link = await videoEditing.buidLink(item.ID);
        text = text.replaceAll("%LINK%", final_link);
        html = html.replaceAll("%LINK%", "<a href='" + final_link + "'>" + final_link + "</a>");
      } else if (item.video_id) {
        text = config.emails.rrEditing;
        html = config.emails.rrEditing;
        let final_link = await videoEditing.buildSourceLink(item.ID);
        text = text.replaceAll("%LINK%", final_link);
        html = html.replaceAll("%LINK%", "<a href='" + final_link + "'>" + final_link + "</a>");
      }

      if (text) {
        let support_mail = "coordinamentomontaggi@radioregione.it";
        let review_link = "https://recensioni.radioregione.it/ospiti";
        let redaction = "Staff Radio Regione";
        text = text.replaceAll("%EMAILSUPPORT%", support_mail);
        text = text.replaceAll("%REVIEWS%", review_link);
        text = text.replaceAll("%STAFF%", redaction);
        text = text.replaceAll("%NOMEOSPITE%", item.nome_intervistato_1);
        html = html.replaceAll("\r\n","<BR/>");
        html = html.replaceAll("%EMAILSUPPORT%", support_mail);
        html = html.replaceAll("%REVIEWS%", review_link);
        html = html.replaceAll("%STAFF%", redaction);
        html = html.replaceAll("%NOMEOSPITE%", item.nome_intervistato_1);

        this.$refs.msgBox
          .show(
            this.$t("videoediting-send-email-text"),
            this.$t("videoediting-send-email-title")
          )
          .then(() => {
            videoEditing.sendMail(item.ID, text,html,subject).then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("videoediting-send-email-success"),
                  this.$t("videoediting-send-email-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    sendWA(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+") != 0) phoneNumber = "+39" + phoneNumber;

      let text = config.emails.rrEditing;
      let support_mail = "coordinamentomontaggi@radioregione.it";
      let review_link = "https://recensioni.radioregione.it/ospiti";
      let redaction = "Staff Radio Regione";
      text = text.replaceAll("%EMAILSUPPORT%", support_mail);
      text = text.replaceAll("%REVIEWS%", review_link);
      text = text.replaceAll("%STAFF%", redaction);
      text = text.replaceAll("%NOMEOSPITE%", item.nome_intervistato_1);

      if (item.path) {
        videoEditing.buidLink(item.ID).then((final_link) => {
          text = text.replaceAll("%LINK%", final_link);
          let url =
            "https://api.whatsapp.com/send?phone=" +
            encodeURIComponent(phoneNumber) +
            "&text=" +
            encodeURIComponent(text);
          this.openUrl(url);
        });
      } else if (item.video_id) {
        videoEditing.buildSourceLink(item.ID).then((final_link) => {
          text = text.replaceAll("%LINK%", final_link);
          let url =
            "https://api.whatsapp.com/send?phone=" +
            encodeURIComponent(phoneNumber) +
            "&text=" +
            encodeURIComponent(text);
          this.openUrl(url);
        });
        // Send original link
      } else {
        let url =
          "https://api.whatsapp.com/send?phone=" +
          encodeURIComponent(phoneNumber);
        this.openUrl(url);
      }
    },

    sendCompletedWA(item) {
      let phoneNumber = item.telefono;
      if (phoneNumber.indexOf("+") != 0) phoneNumber = "+39" + phoneNumber;

      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "https://api.whatsapp.com/send?phone=" + encodeURIComponent(phoneNumber)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    uploadFile: function(item) {
      this.$refs.fileUploader.setPath(item, item.ID + "/");
      this.fileUploader = true;
    },

    handleUploadCompleted: function(/*payload*/) {
      //console.log(payload);
      this.fileUploader = false;
      this.fetchAssignedGuests();
      this.fetchCompletedGuests();
    },

    removeFile(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-file-text"),
          this.$t("videoediting-remove-file-title")
        )
        .then(() => {
          videoEditing.removeFile(item.ID).then(() => {
            this.fetchAssignedGuests();
            this.fetchCompletedGuests();
          });
        })
        .catch(() => {});
    },

    downloadFile: (item /*name*/) => {
      videoEditing
        .downloadFile(item)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    buildLink(item) {
      videoEditing
        .buidLink(item.ID)
        .then((res) => {
          navigator.clipboard.writeText(res);
          this.$refs.msgBox
            .show(res, this.$t("videoediting-download-link"), true)
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },

    buildSourceLink(item) {
      videoEditing
        .buildSourceLink(item.ID)
        .then((res) => {
          navigator.clipboard.writeText(res);
          this.$refs.msgBox
            .show(res, this.$t("videoediting-download-link"), true)
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
