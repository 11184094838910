<template>
  <div>
    <messageBox ref="msgBox" />

    <v-card class="ma-4">
      <v-toolbar color="#0044a3">
        <v-icon color="white" large class="mr-2">mdi-chart-bar</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("speakers-statistics-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <v-row>
        <v-col>
          <v-btn
            :disabled="fetchingData || loading"
            color="success"
            @click.stop="downloadExcelFile()"
            ><v-icon>mdi-file-excel</v-icon
            >{{ $t("speakers-stats-export") }}</v-btn
          >
        </v-col>
      </v-row>

      <div style="margin:15px">
        <v-row justify="center">
          <v-col v-if="!$store.state.user.locked_type" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.type"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_types"
              :label="$t('guests-filter-type')"
              hide-details
            />
          </v-col>

          <v-col class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.scheduler"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_schedulers"
              :label="$t('guests-filter-scheduler')"
              hide-details
            />
          </v-col>

          <v-col v-if="!$store.state.user.locked_locale" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.locale"
              outlined
              item-text="name"
              item-value="code"
              :items="flt_locales"
              :label="$t('guests-filter-locale')"
              hide-details
            />
          </v-col>

          <v-col v-if="!$store.state.user.locked_radio" class="text-center">
            <v-select
              :disabled="fetchingData || loading"
              dense
              v-model="filters.radio"
              outlined
              item-text="name"
              item-value="id"
              :items="flt_radios"
              :label="$t('guests-filter-radio')"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col class="text-center">
            <v-text-field
              :disabled="fetchingData || loading"
              :value="filter"
              @change="(v) => (filter = v)"
              append-icon="mdi-magnify"
              :label="$t('gbl-search')"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              :label="$t('filters-period-hint')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
              :disabled="fetchingData"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :disabled="fetchingData || filters.filterByPeriod != 9"
                  :label="$t('filters-startdate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :disabled="fetchingData || filters.filterByPeriod != 9"
                  :label="$t('filters-enddate-hint')"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="speakers"
        :options.sync="options"
        :server-items-length="totalSpeakers"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.interviews_ratio="{ item }">
          {{
            item.interviews_ratio ? item.interviews_ratio.toFixed(2) + " %" : ""
          }}
        </template>

        <template v-slot:item.first_name="{ item }">
          <v-avatar size="36px" v-if="checkGravatar(item) && getGravatar(item)">
            <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
          </v-avatar>

          <v-avatar
            v-else-if="getProfileLetters(item)"
            color="blue"
            size="36px"
          >
            <span class="profile-text">{{ getProfileLetters(item) }}</span>
          </v-avatar>
          {{ item.first_name }} {{ item.last_name }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import speakersManager from "../apis/speakers";
import staticData from "../apis/static";
import config from "../config";
import messageBox from "../components/MessageBox";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      date_start_modal: false,
      date_end_modal: false,

      searchSpeakerInput: null,
      speaker: null,

      speakers: [],

      flt_locales: [],
      flt_radios: [],
      flt_schedulers: [],
      flt_types: [],

      isLoadingSpeaker: false,
      loading: false,

      totalSpeakers: 0,
      perPage: staticData.defaultPerPage,

      options: {
        sortBy: ["last_name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],
      filter: null,
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        speaker: null,
        scheduler: null,
        locale: "IT",
        radio: null,
        type: 2,
        filterByPeriod: 8,
      },

      fetchingData: false,
    };
  },

  components: {
    messageBox,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("speakers-tbl-firstname"),
        align: "left",
        sortable: true,
        value: "first_name",
      },
      {
        text: this.$t("speakers-tbl-lastname"),
        align: "left",
        sortable: true,
        value: "last_name",
      },
      {
        text: this.$t("speakers-tbl-code"),
        align: "center",
        sortable: true,
        value: "ope_code",
      },
      {
        text: this.$t("speakers-tbl-num-interviews"),
        align: "center",
        sortable: true,
        value: "num_interviews",
      },
      {
        text: this.$t("speakers-tbl-num-payed-interviews"),
        align: "center",
        sortable: true,
        value: "num_payed_interviews",
      },
      {
        text: this.$t("speakers-tbl-num-payed-ratio"),
        align: "center",
        sortable: true,
        value: "interviews_ratio",
      },
    ];

    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];

    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.flt_types = [...this.$store.state.types];

    this.speakers = [];
  },

  watch: {
    options: {
      handler() {
        this.speakers = [];
        this.totalSpeakers = 0;
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadSpeakers(true);
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },

    filters: {
      handler() {
        this.speakers = [];
        this.totalSpeakers = 0;
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadSpeakers(true);
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },
    },
  },

  methods: {
    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    getPeriodFilters() {
      return staticData.filtersPeriods();
    },

    downloadExcelFile() {
      speakersManager.downloadSpeakersExcelStatistics(
        this.$store.state.user.locale,
        this.filters
      );
    },

    reloadSpeakers: function(showLoader) {
      this.speakers = [];
      this.totalSpeakers = 0;
      this.fetchSpeakers(showLoader)
        .then((data) => {
          this.speakers = data.items;
          this.totalSpeakers = data.totalCount;
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    },

    fetchSpeakers(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        speakersManager
          .fetchSpeakersStatistics(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            console.log(err);
            reject();
          });
      });
    },
  },
};
</script>

<style>
.rs-bold {
  font-weight: 600;
}

a {
  color: #0044a3 !Important;
}
</style>
