<template>
  <div>
    <v-dialog
      persistent
      overlay-opacity="0.6"
      max-width="1024"
      :value="modelEditorDialog"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1" class="pa-0"
                    ><v-icon large>mdi-home</v-icon></v-col
                  >
                  <v-col
                    align="center"
                    cols="10"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("scheduler-templates-editor-title") }}</v-col
                  >
                  <v-col cols="1" class="pa-0"
                    ><v-icon @click="closeModelEditorDialog" large
                      >mdi-close</v-icon
                    ></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
              <v-row>
              <v-col cols=2>
              Orario inizio
              </v-col>
              <v-col align='left'>
                <vuetify-time-select
                  label=""
                  :minuteGroups="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
                  v-model="model_slice_start_time"
                ></vuetify-time-select>
                </v-col>
                
              <v-col cols=2>
              Orario fine
              </v-col>
              <v-col align='left' >
                <vuetify-time-select
                  label=""
                  :minuteGroups="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
                  v-model="model_slice_end_time"
                ></vuetify-time-select>
                 </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-card class="ma-4">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-calendar</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("scheduler-templates-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="addModel()"><v-icon>mdi-plus</v-icon></v-btn>
      </v-toolbar>
      <div style="height:10px" />

      <v-row class="ml-2 mr-2">
        <v-col>
          <v-text-field
            dense
            outlined
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto ml-1 pl-1"
            :label="$t('gbl-search')"
            hide-details
          />
        </v-col>
      </v-row>

      <div class="vertical-spacer" />
      <v-data-table
        class="grid-table ml-2 mr-2"
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="models"
        :options.sync="options"
        :server-items-length="totalModes"
        :loading="loading"
        :footer-props="dataTableFooterOptions"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import staticData from "../apis/static";
import schedulerManager from "../apis/scheduler";
import config from "../config";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      models: [],
      loading: false,
      totalModels: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: null,
      model_slice_start_time: "00:00",
      model_slice_end_time: "00:00",

      modelEditorDialog: false,
    };
  },

  components: {},

  mounted() {
    this.headers = [
      {
        text: this.$t("scheduler-templates-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("scheduler-templates-tbl-num-slots"),
        align: "center",
        sortable: true,
        value: "slots",
      },
      {
        text: this.$t("scheduler-templates-tbl-times-used"),
        align: "center",
        sortable: true,
        value: "used",
      },
    ];
  },

  watch: {
    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadOperators(true);
        }, config.searchInputsFastTimeout);
      },
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadOperators(false);
      },
    },
  },

  methods: {
    addModel() {
      this.modelEditorDialog = true;
    },

    closeModelEditorDialog() {
      this.modelEditorDialog = false;
    },

    fetchModels() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        schedulerManager
          .fetchModels(ctx)
          .then((result) => {
            this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
  },
};
</script>
