<template>
  <div>
    <messageBox ref="msgBox" />
    <EditorsFileUploader
      ref="fileUploader"
      @uploadCompleted="handleUploadCompleted"
      v-model="fileUploader"
    />
    <v-card class="ma-2">
      <v-toolbar color="#0044a3" dark>
        <v-app-bar-nav-icon
          ><v-icon large>mdi-video-account</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">{{
          $t("videoediting-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row dense v-if="fetchingFilters" class="text-center">
        <v-col>
          <v-progress-circular
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-card-text>
        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("videoediting-assigned")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :headers="assignedGuestsHeaders"
              :items="assignedGuests"
              :options.sync="assignedGuestsOptions"
              :server-items-length="totalAssignedGuests"
              :loading="fetchingAssignedGuests"
              item-key="id"
            >
              <template v-slot:item.path="{ item }">
                {{ item.path ? item.path.split("/").slice(-1)[0] : "" }}
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.data_convalida="{ item }">
                {{ item.data_convalida | toLocaleDate }}
              </template>

              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | toLocaleDate }}
              </template>

              <template v-slot:item.videoediting_working_datetime="{ item }">
                {{ item.videoediting_working_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.media_states="{ item }">
                <div style="width:64px;">
                  <span v-if="!item.audio_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-audio") }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!item.video_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-video") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="width:200px; ">
                  <v-tooltip v-if="$store.state.hasRight('175')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="uploadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-upload</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-upload")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('176')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="downloadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-download</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('177')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeFile(item)"
                        v-on="on"
                        ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-remove-file")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('178')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="buildLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-link")
                    }}</span></v-tooltip
                  >


                  <v-tooltip
                    v-if="item.video_id && !item.path && $store.state.hasRight('178')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="purple"
                        @click.stop="buildSourceLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-source-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('80')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        v-if="!item.videoediting_working_datetime"
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="flagWorking(item)"
                        v-on="on"
                        ><v-icon>mdi-check</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-set-working")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('80')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        v-if="item.videoediting_working_datetime"
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeWorking(item)"
                        v-on="on"
                        ><v-icon>mdi-check</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-unset-working")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('80')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="flagEditingCompleted(item)"
                        v-on="on"
                        ><v-icon>mdi-flag</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-complete")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <div style="height:30px" />

        <v-card class="ma-2">
          <v-toolbar height="40" color="#0044a3" dark>
            <v-toolbar-title class="white--text mt-1">{{
              $t("videoediting-completed")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              dense
              :headers="completedGuestsHeaders"
              :items="completedGuests"
              :options.sync="completedGuestsOptions"
              :server-items-length="totalCompletedGuests"
              :loading="fetchingCompletedGuests"
              item-key="id"
            >
              <template v-slot:item.path="{ item }">
                {{ item.path ? item.path.split("/").slice(-1)[0] : "" }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="min-width:80px"></div>
              </template>

              <template v-slot:item.data_intervista="{ item }">
                {{ item.data_intervista | toLocaleDate }}
              </template>

              <template v-slot:item.data_convalida="{ item }">
                {{ item.data_convalida | toLocaleDate }}
              </template>

              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | toLocaleDate }}
              </template>

              <template v-slot:item.videoediting_working_datetime="{ item }">
                {{ item.videoediting_working_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.videoediting_completed_datetime="{ item }">
                {{ item.videoediting_completed_datetime | toLocaleDateTime }}
              </template>

              <template v-slot:item.ID="{ item }">
                <span v-html="getItemID(item)" />
              </template>

              <template v-slot:item.media_states="{ item }">
                <div style="width:48px;">
                  <span v-if="!item.audio_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-audio") }}</span>
                    </v-tooltip>
                  </span>
                  <span v-if="!item.video_id">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                          mdi-alert
                        </v-icon>
                      </template>
                      <span>{{ $t("speakers-warning-no-video") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="width:200px;">
                  <v-tooltip v-if="$store.state.hasRight('175')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="uploadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-upload</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-upload")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('176')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="downloadFile(item)"
                        v-on="on"
                        ><v-icon>mdi-cloud-download</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-download")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('177')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="removeFile(item)"
                        v-on="on"
                        ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-remove-file")
                    }}</span></v-tooltip
                  >

                  <v-tooltip
                    v-if="item.path && $store.state.hasRight('174')"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click.stop="buildLink(item)"
                        v-on="on"
                        ><v-icon>mdi-content-copy</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-action-build-link")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="$store.state.hasRight('80')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="orange"
                        @click.stop="removeEditingCompleted(item)"
                        v-on="on"
                        ><v-icon>mdi-flag</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("videoediting-remove-complete")
                    }}</span></v-tooltip
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import videoEditing from "../apis/videoediting";
import guestsManager from "../apis/guests";
import config from "../config";

import messageBox from "../components/MessageBox";
import EditorsFileUploader from "../components/EditorsFileUploader";

export default {
  data() {
    return {
      assignedGuestsHeaders: [],
      completedGuestsHeaders: [],
      assignedGuests: [],
      completedGuests: [],
      totalAssignedGuests: 0,
      totalCompletedGuests: 0,

      fetchingAssignedGuests: false,
      fetchingCompletedGuests: false,
      fetchingFilters: false,

      assignedGuestsOptions: {
        sortBy: ["data_pagamento"],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },

      completedGuestsOptions: {
        sortBy: ["data_pagamento"],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },

      date_start_modal: false,
      date_end_modal: false,

      searchOperatorInput: null,
      searchCoordinatorInput: null,
      searchSpeakerInput: null,

      operator: null,
      speaker: null,
      coordinator: null,

      guests: [],
      operators: [],
      coordinators: [],
      speakers: [],
      users: [],

      flt_outcomes: [],
      flt_locales: [],
      flt_radios: [],
      flt_types: [],
      flt_schedulers: [],

      flt_users: {},

      isLoadingOperator: false,
      isLoadingCoordinator: false,
      isLoadingSpeaker: false,

      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        outcome: null,
        coordinator: null,
        speaker: null,
        operator: null,
        scheduler: null,
        type: null,
        locale: null,
        radio: null,
      },

      filter: null,
      fileUploader: false,
    };
  },

  name: "VideoEditing",

  components: {
    messageBox,
    EditorsFileUploader,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      },
    },
  },

  destroyed() {},

  mounted() {
    this.assignedGuestsHeaders = [
      {
        text: this.$t("guests-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("guests-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "data_intervista",
      },

      {
        text: this.$t("guests-tbl-validation-date"),
        align: "center",
        sortable: true,
        value: "data_convalida",
      },

      {
        text: this.$t("guests-tbl-payment-date"),
        align: "center",
        sortable: true,
        value: "data_pagamento",
      },

      {
        text: this.$t("guests-tbl-name"),
        align: "left",
        sortable: false,
        value: "nome_intervistato_1",
      },

      {
        text: this.$t("guests-tbl-packet-version"),
        align: "left",
        sortable: false,
        value: "nome_pacchetto",
      },

      {
        text: this.$t("guests-tbl-date-started"),
        align: "center",
        sortable: false,
        value: "videoediting_working_datetime",
      },

      {
        text: this.$t("guests-tbl-path"),
        align: "left",
        sortable: false,
        value: "path",
      },

      { text: "", align: "center", sortable: false, value: "media_states" },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];

    this.completedGuestsHeaders = [
      {
        text: this.$t("guests-tbl-code"),
        align: "left",
        sortable: true,
        value: "ID",
      },
      {
        text: this.$t("guests-tbl-interview-date"),
        align: "center",
        sortable: true,
        value: "data_intervista",
      },

      {
        text: this.$t("guests-tbl-validation-date"),
        align: "center",
        sortable: true,
        value: "data_convalida",
      },

      {
        text: this.$t("guests-tbl-payment-date"),
        align: "center",
        sortable: true,
        value: "data_pagamento",
      },

      {
        text: this.$t("guests-tbl-name"),
        align: "left",
        sortable: false,
        value: "nome_intervistato_1",
      },

      {
        text: this.$t("guests-tbl-packet-version"),
        align: "left",
        sortable: false,
        value: "nome_pacchetto",
      },

      {
        text: this.$t("guests-tbl-date-started"),
        align: "center",
        sortable: false,
        value: "videoediting_working_datetime",
      },

      {
        text: this.$t("guests-tbl-date-completed"),
        align: "center",
        sortable: false,
        value: "videoediting_completed_datetime",
      },

      {
        text: this.$t("guests-tbl-path"),
        align: "left",
        sortable: false,
        value: "path",
      },

      { text: "", align: "center", sortable: false, value: "media_states" },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];

    this.flt_locales = [
      { code: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.tr_locales,
    ];

    this.flt_radios = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.radios,
    ];

    this.flt_schedulers = [
      { id: null, name: this.$t("gbl-all-male") },
      ...this.$store.state.schedulers,
    ];

    this.flt_types = [
      { id: null, name: this.$t("gbl-all-female") },
      ...this.$store.state.types,
    ];

    this.operators = [];
    this.speakers = [];
    this.coordinators = [];

    this.fetchUsers()
      .then((res) => {
        this.flt_users = {};
        this.users = res.items;
        this.users.map((x) => {
          this.flt_users[x.id] = x;
        });
        this.fetchingFilters = false;
      })
      .catch((err) => {
        this.fetchingFilters = false;
        console.log(err);
      });
  },

  watch: {
    
    assignedGuestsOptions: {
      handler() {
        this.fetchAssignedGuests();
      },
      deep: true,
    },

    completedGuestsOptions: {
      handler() {
        this.fetchCompletedGuests();
      },
      deep: true,
    },

    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperator = false;
            })
            .catch(() => {
              this.isLoadingOperator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchCoordinatorInput(val) {
      clearTimeout(this._searchCoordinatorInput);
      if (val) {
        this._searchCoordinatorInput = setTimeout(() => {
          this.isLoadingCoordinator = true;
          this.fetchUsers(val)
            .then((res) => {
              this.coordinators = res.items;
              this.isLoadingCoordinator = false;
            })
            .catch(() => {
              this.isLoadingCoordinator = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchSpeakerInput(val) {
      clearTimeout(this._searchSpeakerInput);
      if (val) {
        this._searchSpeakerInput = setTimeout(() => {
          this.isLoadingSpeaker = true;
          this.fetchUsers(val)
            .then((res) => {
              this.speakers = res.items;
              this.isLoadingSpeaker = false;
            })
            .catch(() => {
              this.isLoadingSpeaker = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    operator: {
      handler() {
        this.filters.operator = this.operator ? this.operator.id : null;
      },
    },

    speaker: {
      handler() {
        this.filters.speaker = this.speaker ? this.speaker.id : null;
      },
    },

    coordinator: {
      handler() {
        this.filters.coordinator = this.coordinator
          ? this.coordinator.id
          : null;
      },
    },

    filters: {
      handler() {
        this.guests = [];
        this.totalGuests = 0;

        this.assignedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.completedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };
      },

      deep: true,
    },

    filter: {
      handler() {
        this.assignedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };

        this.completedGuestsOptions = {
          sortBy: ["data_pagamento"],
          sortDesc: [false],
          itemsPerPage: 10,
          page: 1,
        };
      },
    },
  },

  methods: {

    fetchUsers(filter) {
      if (this.$store.state.tr_locales) {
        this.flt_locales = [
          { code: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.tr_locales,
        ];
      }
      if (this.$store.state.radios) {
        this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.radios,
        ];
      }
      if (this.$store.state.schedulers) {
        this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.schedulers,
        ];
      }

      if (this.$store.state.types) {
        this.flt_types = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.types,
        ];
      }

      return new Promise((resolve, reject) => {
        guestsManager
          .fetchUsers(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    getItemID(item) {
      if (item.id_ospite) {
        return item.sigla_codice + "-" + item.id_ospite;
      } else {
        return item.sigla_codice + "-" + item.ID;
      }
    },

    fetchAssignedGuests() {
      this.fetchingAssignedGuests = true;
      this.assignedGuests = [];
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.assignedGuestsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = this.assignedGuestsOptions.sortBy[0];
        ctx.sortDesc = this.assignedGuestsOptions.sortDesc[0];
        videoEditing
          .fetchEditorAssignedGuests(ctx)
          .then((result) => {
            this.assignedGuests = result.items;
            this.totalAssignedGuests = result.totalCount;
            this.fetchingAssignedGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingAssignedGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    fetchCompletedGuests() {
      this.fetchingCompletedGuests = true;
      this.completedGuests = [];
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.completedGuestsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = this.completedGuestsOptions.sortBy[0];
        ctx.sortDesc = this.completedGuestsOptions.sortDesc[0];
        videoEditing
          .fetchEditorCompletedGuests(ctx)
          .then((result) => {
            this.completedGuests = result.items;
            this.totalCompletedGuests = result.totalCount;
            this.fetchingCompletedGuests = false;
            resolve();
          })

          .catch((err) => {
            this.fetchingCompletedGuests = false;
            if (err) console.log(err);
            reject(err);
          });
      });
    },

    flagEditingCompleted(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-flag-completed-text"),
          this.$t("videoediting-flag-completed-title")
        )
        .then(() => {
          videoEditing.flagEditingCompleted(item.ID).then(() => {
            this.fetchCompletedGuests();
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    removeEditingCompleted(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-completed-text"),
          this.$t("videoediting-remove-completed-title")
        )
        .then(() => {
          videoEditing.removeEditingCompleted(item.ID).then(() => {
            this.fetchCompletedGuests();
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    flagWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-flag-working-text"),
          this.$t("videoediting-flag-working-title")
        )
        .then(() => {
          videoEditing.flagWorking(item.ID).then(() => {
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    removeWorking(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-working-text"),
          this.$t("videoediting-remove-working-title")
        )
        .then(() => {
          videoEditing.removeWorking(item.ID).then(() => {
            this.fetchAssignedGuests();
          });
        })
        .catch(() => {});
    },

    uploadFile: function(item) {
      this.$refs.fileUploader.setPath(item, item.ID + "/");
      this.fileUploader = true;
    },

    handleUploadCompleted: function(/*payload*/) {
      //console.log(payload);
      this.fileUploader = false;
      this.fetchAssignedGuests();
      this.fetchCompletedGuests();
    },

    removeFile(item) {
      this.$refs.msgBox
        .show(
          this.$t("videoediting-remove-file-text"),
          this.$t("videoediting-remove-file-title")
        )
        .then(() => {
          videoEditing.removeFile(item.ID).then(() => {
            this.fetchAssignedGuests();
            this.fetchCompletedGuests();
          });
        })
        .catch(() => {});
    },

    downloadFile: (item /*name*/) => {
      videoEditing
        .downloadFile(item)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    buildLink(item) {
      videoEditing
        .buidLink(item.ID)
        .then((res) => {
          navigator.clipboard.writeText(res);
          this.$refs.msgBox
            .show(res, this.$t("videoediting-download-link"), true)
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },

    buildSourceLink(item) {
      videoEditing
        .buildSourceLink(item.ID)
        .then((res) => {
          navigator.clipboard.writeText(res);
          this.$refs.msgBox
            .show(res, this.$t("videoediting-download-link"), true)
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
