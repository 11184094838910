<template>
  <v-dialog
    overlay-opacity="0.6"
    persistent
    max-width="800"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="blue">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-video-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("videoediting-operator-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form >
              <div style="height:10px;" />

              <v-row v-if="processing" class="text-center">
                <v-col>
                  <div style="height:10px;" />
                  <v-progress-circular
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-col>
              </v-row>

              <ValidationObserver
                v-if="!processing"
                ref="obs"
                v-slot="{ invalid, validated }"
              >
                <v-row>
                  <v-col>
                    <ValidationProvider
                      immediate
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-autocomplete
                        prepend-icon="mdi-account"
                        clearable
                        dense
                        v-model="operator"
                        :items="operators"
                        :loading="isLoadingOperators"
                        :search-input.sync="searchOperatorInput"
                        cache-items
                        outlined
                        item-text="name"
                        item-value="id"
                        :label="$t('videoediting-operator-label')"
                        return-object
                        hide-no-data
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="invalid || !validated"
                      v-on:click="confirm()"
                      color="cyan"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="gray">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import videoManager from "../../apis/videoediting";
import config from "../../config";

export default {
  data() {
    return {
      isLoadingOperators: false,
      operators: [],
      searchOperatorInput: null,
      operator: null,
      guest_speaker: null,
      fetchingMetadata: false,
      openDialog: false,
      processing: false,
      payloadItem: null,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mounted() {},

  watch: {
    searchOperatorInput(val) {
      clearTimeout(this._searchOperatorInput);
      if (val) {
        this._searchOperatorInput = setTimeout(() => {
          this.isLoadingOperators = true;
          this.fetchUsers(val)
            .then((res) => {
              this.operators = res.items;
              this.isLoadingOperators = false;
            })
            .catch(() => {
              this.isLoadingOperators = false;
            });
        }, config.searchInputsTimeout);
      }
    },
  },

  methods: {
    fetchUsers(filter) {
      return new Promise((resolve, reject) => {
        videoManager
          .fetchEditors(filter)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.openDialog = false;
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs.reset();
        this.processing = true;
        this.$emit("onOperatorChoosen", {
          item: this.payloadItem,
          selection: this.operator.id,
        });
      });
    },

    resetModal() {
      this.processing = false;
    },

    applyModalValidation() {
      requestAnimationFrame(() => {
        if (this.$refs.obs) {
          this.$refs.obs.validate();
        }
      });
    },

    openModal(item) {
      this.resetModal();
      this.openDialog = true;
      this.payloadItem = item;
    },
  },
};
</script>
