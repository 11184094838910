<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1280" :value="value">
    <SetString ref="renamePermission" />
    <MessageBox ref="msgBox" />
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0 text-left"
                  ><v-icon large>mdi-speaker</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("audioepisodes-add-episode-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-card-text>
                <audioFilters
                  :filters="filters"
                  :streamDates="false"
                  :showDateType="false"
                  :fetchingData="fetchingData"
                  @filtersReady="filtersReady"
                  :streamStates="false"
                  :waState="false"
                  :mailState="false"
                  :purchaseState="true"
                />

                <v-card class="mt-2 elevation-2">
                  <v-toolbar dense color="blue" dark>
                    <v-col col="1"></v-col>
                    <v-col>{{
                      $t("audio-episodes-editor-tbl-available-podcasts")
                    }}</v-col>

                    <v-col class="text-right" col="1">
                      <v-btn icon>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      height="300"
                      dense
                      :must-sort="true"
                      :headers="headers"
                      :items="podcasts"
                      :options.sync="options"
                      :server-items-length="totalPodcasts"
                      :loading="fetchingData"
                      :footer-props="dataTableFooterOptions"
                      show-select
                      v-model="selected_podcasts"
                      item-key="id"
                    >
                      <template v-slot:item.guest_id="{ item }">
                        OSP-{{ item.guest_id }}
                      </template>

                      <template v-slot:item.interview_date="{ item }">
                        {{ item.interview_date | toLocaleDate }}
                      </template>

                      <template v-slot:item.select_state="{ item }">
                        <v-tooltip
                          v-if="
                            !item.isSelectable &&
                              !episode.episode_podcasts.includes(item)
                          "
                          bottom
                          ><template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red"
                              >mdi-check-circle-outline</v-icon
                            > </template
                          ><span>{{
                            $t("audio-episodes-editor-tbl-already-included") +
                              item.episode_name
                          }}</span></v-tooltip
                        >

                        <v-icon
                          color="green"
                          v-if="episode.episode_podcasts.includes(item)"
                          >mdi-check</v-icon
                        >
                      </template>
                    </v-data-table>
                    <v-btn
                      @click="addSelected()"
                      :disabled="selected_podcasts.length < 1"
                      color="success"
                      >{{ $t("audio-episodes-editor-add-selected") }}</v-btn
                    >
                  </v-card-text>

                  <v-card class="mt-2 elevation-2">
                    <v-toolbar dense color="blue" dark>
                      <v-col col="1"></v-col>
                      <v-col>{{
                        $t("audio-episodes-editor-tbl-episode-content")
                      }}</v-col>

                      <v-col col="1"> </v-col>
                    </v-toolbar>

                    <v-card-text>
                      <v-row>
                        <v-col>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              class="mt-1 ml-1 mr-1"
                              v-model="episode.name"
                              :label="$t('audio-episodes-editor-hint-name')"
                              prepend-icon="mdi-circle-edit-outline"
                              :error-messages="errors"
                              :success="valid"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              class="mt-1 ml-1 mr-1"
                              v-model="episode.description"
                              :label="
                                $t('audio-episodes-editor-hint-description')
                              "
                              prepend-icon="mdi-circle-edit-outline"
                              :error-messages="errors"
                              :success="valid"
                              outlined
                              dense
                              required
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-chip
                        v-for="podcast in episode.episode_podcasts"
                        :key="podcast.id"
                        class="mr-1 mb-1"
                        close
                        dark
                        close-icon="mdi-delete"
                        color="purple"
                        label
                        @click:close="removePodcast(podcast)"
                        >OSP-{{ podcast.guest_id }}</v-chip
                      >

                      <v-row
                        ><v-col class="body-1"
                          >{{ $t("audio-episodes-editor-length") }} :
                          {{ formatLength(episode.episode_length) }}</v-col
                        ></v-row
                      >

                      <v-row
                        ><v-col>
                          <v-progress-circular
                            v-if="buildingEpisode"
                            :indeterminate="true"
                            :rotate="0"
                            :size="32"
                            :width="4"
                            color="light-blue"
                          ></v-progress-circular>
                          <v-btn
                            v-if="!buildingEpisode"
                            @click="buildEpisode()"
                            color="success"
                            :disabled="
                              invalid ||
                                !validated ||
                                episode.episode_length == 0
                            "
                            >{{ $t("audio-episodes-editor-build") }}</v-btn
                          ></v-col
                        ></v-row
                      >
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-card-text>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import staticData from "../../apis/static";
import config from "../../config";
import audioManager from "../../apis/audio";

import SetString from "../SetString";
import MessageBox from "../MessageBox.vue";
import audioFilters from "../../components/audio/AudioFilters";

import { Duration as LuxonDuration } from "luxon";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  data() {
    return {
      buildingEpisode: false,
      selected_podcasts: [],

      episode: {
        episode_podcasts: [],
        episode_length: 0,
        name: "",
        description: "",
      },
      podcasts: [],
      totalPodcasts: 0,
      editingEpisode: false,
      fetchingData: false,
      fetchingFilters: false,

      filters: {
        filter: null,
        region: null,
        category: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        dateTypes: 1,
        speakers: null,
        purchased: null,
        radio: null,
        locale: null,
      },

      headers: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      options: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: staticData.defaultPerPage,
        page: 1,
      },
    };
  },

  components: {
    SetString,
    MessageBox,
    audioFilters,
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    this.headers = [
      { text: "", sortable: false, value: "select_state" },
      {
        text: this.$t("audio-episodes-editor-tbl-guest-code"),
        align: "left",
        sortable: true,
        value: "guest_id",
      },
      {
        text: this.$t("audio-episodes-editor-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      
      {
        text: this.$t("audio-episodes-editor-tbl-interview-date"),
        align: "left",
        sortable: true,
        value: "interview_date",
      },
      {
        text: this.$t("audio-episodes-editor-tbl-speaker"),
        align: "left",
        sortable: true,
        value: "speaker_name",
      },
      {
        text: this.$t("audio-episodes-editor-tbl-length"),
        align: "left",
        sortable: true,
        value: "duration",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.currentPage = 1;
          this.loadPodcasts();
        }, config.searchInputsFastTimeout);
      },

      deep: true,
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.loadPodcasts();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },
  },

  methods: {
    buildEpisode() {
      this.buildingEpisode = true;
      this.$emit("episodeConfigured", {
        editingEpisode: this.editingEpisode,
        episode: this.episode,
      });
    },

    formatLength(value) {
      let duration = LuxonDuration.fromMillis(value * 1000);
      duration = duration.shiftTo("hours", "minutes", "seconds").toObject();
      let formattedDuration = "{0} {1}, {2} {3}, {4} {5}".formatUnicorn(
        duration.hours.toFixed(0),
        this.$t("gbl-hours"),
        duration.minutes.toFixed(0),
        this.$t("gbl-minutes"),
        duration.seconds.toFixed(0),
        this.$t("gbl-seconds")
      );
      return formattedDuration;
    },

    addSelected() {
      for (let n = 0; n < this.selected_podcasts.length; n++) {
        this.episode.episode_podcasts.push(this.selected_podcasts[n]);
      }
      this.selected_podcasts = [];
      this.filterSelection();
      this.calculateLength();
    },

    removePodcast(podcast) {
      this.episode.episode_podcasts = this.episode.episode_podcasts.filter(
        function(item) {
          return item !== podcast;
        }
      );
      this.filterSelection();
      this.calculateLength();
    },

    calculateLength() {
      this.episode.episode_length = 0;
      for (let n = 0; n < this.episode.episode_podcasts.length; n++) {
        let podcast = this.episode.episode_podcasts[n];
        this.episode.episode_length += podcast.duration;
      }
    },

    filtersReady: function() {
      this.fetchingFilters = false;
      this.currentPage = 1;
      this.loadPodcasts();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      if (!this.buildingEpisode) {
        if (this.$refs.obs) this.$refs.obs.reset();
        this.$emit("episodeConfigured", null);
      }
    },

    resetModal() {
      this.buildingEpisode = false;
      this.episode.name = "";
      this.episode.description = "";
      this.episode.episode_podcasts = [];
      this.episode.episode_length = 0;
      requestAnimationFrame(() => {
        if (this.$refs.obs) this.$refs.obs.reset();
      });

      this.loadPodcasts();
    },

    setModalEpisode(episode, editing) {
      this.episode = Object.assign({}, episode);
      this.editingEpisode = editing;
      if (!this.editingEpisode) this.resetModal();
    },

    filterSelection() {
      this.podcasts = this.podcasts.map((x) => {
        let podcast = x;
        podcast.isSelectable =
          !this.episode.episode_podcasts.includes(podcast) &&
          !podcast.episode_id;
        return podcast;
      });
    },

    loadPodcasts() {
      this.podcasts = [];
      this.totalPodcasts = 0;
      this.fetchingData = true;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filters.filter;
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      audioManager
        .fetchPodcastsForEpisodes(ctx)
        .then((result) => {
          this.fetchingData = false;
          this.podcasts = result.items;
          this.filterSelection();
          this.totalPodcasts = result.totalCount;
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },
  },
};
</script>
