var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('audioEpisodeCreator',{ref:"episodeEditor",on:{"episodeConfigured":_vm.handleEpisode},model:{value:(_vm.episodeEditorDialog),callback:function ($$v) {_vm.episodeEditorDialog=$$v},expression:"episodeEditorDialog"}}),_c('episodeBuilderProgress',{ref:"episodeBuilderProgress"}),_c('v-card',{staticClass:"ma-1"},[_c('v-toolbar',{attrs:{"color":"#0044a3","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-cellphone-sound")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("audioepisodes-title")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("audioepisodes-episodes")))]),_c('v-spacer'),(_vm.$store.state.hasRight('150'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.addEpisode()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,617159646)},[_c('span',[_vm._v(_vm._s(_vm.$t("audioepisodes-add-episode")))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","must-sort":true,"headers":_vm.headers,"items":_vm.episodes,"options":_vm.options,"server-items-length":_vm.totalEpisodes,"loading":_vm.fetchingEpisodes,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.creation_date))+" ")]}},{key:"item.episode_length",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedEpisodeLength(item.episode_length))+" ")]}},{key:"item.episode_state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getState(item.episode_state))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"80px"}},[(
                    item.episode_state == 0 && _vm.$store.state.hasRight('151')
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.startItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("audio-episode-action-start")))])]):_vm._e(),(
                    item.episode_state == 2 && _vm.$store.state.hasRight('152')
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("audio-episode-action-download")))])]):_vm._e(),(_vm.$store.state.hasRight('153'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("audio-episode-action-delete")))])]):_vm._e()],1)]}}])})],1)],1),_c('div',{staticStyle:{"height":"30px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }