<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input type="file" ref="file" :name="uploadFieldName" @change="onFileChange($event.target.name, $event.target.files)" style="display:none">
    <!-- error dialog displays any potential errors -->
    <v-dialog v-model="errorDialog" max-width="400">
      <v-container fill-height fluid grid-list-xl> 
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0 " color="red">
          <template v-slot:heading>
              <v-row class="pa-0">
                <v-col align="center" class="pa-0 card-title-text" style="font-size: 25px">{{$t('image-upload-failed')}}</v-col>
                <v-col cols=1 class="pa-0"><v-icon @click="errorDialog = false" large>mdi-close</v-icon></v-col>
              </v-row>
            </template>
            <v-card-text>
                <span style='font-size:20px;'>{{errorText}}</span>
            </v-card-text>
            <v-btn width="120" @click="errorDialog = false" color="primary">{{$t('gbl-ok')}}</v-btn>
          </base-material-card>
        </v-flex>
    </v-layout>
  </v-container>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'image-input',
    data: ()=> ({
      errorDialog: null,
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 5196
    }),
    props: {    
      // Use "value" here to enable compatibility with v-model
      value: Object,
    },
    methods: {
      launchFilePicker(){
        this.$refs.file.click();
      },

      onFileChange(fieldName, file) {
        const { maxSize } = this
        let imageFile = file[0] 
 
        //check if user actually selected a file
        if (file.length>0) {
          let size = imageFile.size / maxSize / maxSize
          if (!imageFile.type.match('image.*')) {
            // check whether the upload is an image
            this.errorDialog = true
            this.errorText = this.$t('image-upload-unsupported');
          } else if (size>1) {
            // check whether the size is greater than the size limit
            this.errorDialog = true
            this.errorText = this.$t('image-upload-sizeerror');
          } else {
            // Append file into FormData & turn file into image URL
            //let formData = new FormData()
            let imageURL = URL.createObjectURL(imageFile)
            //formData.append(fieldName, imageFile)
            // Emit FormData & image URL to the parent component
            this.$emit('input', { /*formData,*/ imageURL })
          }
        }
      }
    }
  }
</script>