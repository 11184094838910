var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('EditorsFileUploader',{ref:"fileUploader",on:{"uploadCompleted":_vm.handleUploadCompleted},model:{value:(_vm.fileUploader),callback:function ($$v) {_vm.fileUploader=$$v},expression:"fileUploader"}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"color":"#0044a3","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-video-account")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("videoediting-title")))]),_c('v-spacer')],1),(_vm.fetchingFilters)?_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"light-blue"}})],1)],1):_vm._e(),_c('v-card-text',[_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("videoediting-assigned")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.assignedGuestsHeaders,"items":_vm.assignedGuests,"options":_vm.assignedGuestsOptions,"server-items-length":_vm.totalAssignedGuests,"loading":_vm.fetchingAssignedGuests,"item-key":"id"},on:{"update:options":function($event){_vm.assignedGuestsOptions=$event}},scopedSlots:_vm._u([{key:"item.path",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.path ? item.path.split("/").slice(-1)[0] : "")+" ")]}},{key:"item.data_intervista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_intervista))+" ")]}},{key:"item.data_convalida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_convalida))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_pagamento))+" ")]}},{key:"item.videoediting_working_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_working_datetime))+" ")]}},{key:"item.ID",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getItemID(item))}})]}},{key:"item.media_states",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"64px"}},[(!item.audio_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-audio")))])])],1):_vm._e(),(!item.video_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-video")))])])],1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px"}},[(_vm.$store.state.hasRight('175'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.uploadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-upload")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('176'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-download")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('177'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-remove-file")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('178'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.buildLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-link")))])]):_vm._e(),(item.video_id && !item.path && _vm.$store.state.hasRight('178'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"purple"},on:{"click":function($event){$event.stopPropagation();return _vm.buildSourceLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-source-link")))])]):_vm._e(),(_vm.$store.state.hasRight('80'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.videoediting_working_datetime)?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.flagWorking(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-set-working")))])]):_vm._e(),(_vm.$store.state.hasRight('80'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.videoediting_working_datetime)?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeWorking(item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-unset-working")))])]):_vm._e(),(_vm.$store.state.hasRight('80'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.flagEditingCompleted(item)}}},on),[_c('v-icon',[_vm._v("mdi-flag")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-complete")))])]):_vm._e()],1)]}}])})],1)],1),_c('div',{staticStyle:{"height":"30px"}}),_c('v-card',{staticClass:"ma-2"},[_c('v-toolbar',{attrs:{"height":"40","color":"#0044a3","dark":""}},[_c('v-toolbar-title',{staticClass:"white--text mt-1"},[_vm._v(_vm._s(_vm.$t("videoediting-completed")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.completedGuestsHeaders,"items":_vm.completedGuests,"options":_vm.completedGuestsOptions,"server-items-length":_vm.totalCompletedGuests,"loading":_vm.fetchingCompletedGuests,"item-key":"id"},on:{"update:options":function($event){_vm.completedGuestsOptions=$event}},scopedSlots:_vm._u([{key:"item.path",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.path ? item.path.split("/").slice(-1)[0] : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px"}},[(_vm.$store.state.hasRight('175'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.uploadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-upload")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('176'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-download")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('177'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-remove-file")))])]):_vm._e(),(item.path && _vm.$store.state.hasRight('174'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.buildLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-action-build-link")))])]):_vm._e(),(_vm.$store.state.hasRight('80'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.removeEditingCompleted(item)}}},on),[_c('v-icon',[_vm._v("mdi-flag")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("videoediting-remove-complete")))])]):_vm._e()],1)]}},{key:"item.data_intervista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_intervista))+" ")]}},{key:"item.data_convalida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_convalida))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.data_pagamento))+" ")]}},{key:"item.videoediting_working_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_working_datetime))+" ")]}},{key:"item.videoediting_completed_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.videoediting_completed_datetime))+" ")]}},{key:"item.ID",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getItemID(item))}})]}},{key:"item.media_states",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"48px"}},[(!item.audio_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-audio")))])])],1):_vm._e(),(!item.video_id)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("speakers-warning-no-video")))])])],1):_vm._e()])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }